import React, { useState, useEffect, useCallback, useMemo, useRef } from "react";
import { Card, Row, Col, List, Spin } from "antd";
import { Table } from "ant-table-extensions";
import { JOB_REPORT_DOWNLOAD_LIST } from "../../../../../../ApiUrl";
import { OpeningStatusList } from "../../../../../pages/constant/constant";
import useReportData from "../../../../../../hooks/useReportData";
import { DoughnutChart } from "./DoughnutChart";

function JobReport(props) {
  const paramsRef = useRef(null)
  const columns = useMemo(() => [
    {
      title: "Opening Title",
      dataIndex: "opening_title",
      key: "opening_title",
    },
    {
      title: "Opening id",
      dataIndex: "opening_id",
      key: "opening_id",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (Code) => {
        return OpeningStatusList.find(status => status.value === Code)?.label || "";
      },
    },
  ], []);

  const [currentPage, setCurrentPage] = useState(1);
  const [chartStatus, setChartStatus] = useState("");

  const initialParams = useMemo(() => ({
    current_page: currentPage,
    per_page: "10",
    sort_order: "desc",
    filter_value: "",
    order: "created_at",
    dateRange: props.arrayDateRange,
    categories: [],
    status: props.statusLabal,
    company_id: "",
    bdm_id: props.bdm_id,
    recruiter_id: props.recruiter_id
  }), [currentPage, props.arrayDateRange, props.statusLabal, props.bdm_id, props.recruiter_id]);

  const { data: openings, loading, error, updateParams } = useReportData(JOB_REPORT_DOWNLOAD_LIST, initialParams);

  useEffect(() => {
    // Initialize paramsRef.current if it is null
    if (!paramsRef.current) {
      paramsRef.current = {
        current_page: currentPage,
        status: props.statusLabal
      };
    }

    // Check if currentPage or chartStatus has changed compared to paramsRef
    if (currentPage !== paramsRef.current.current_page ||
      chartStatus !== paramsRef.current.status) {

      // Update paramsRef to match the latest state values
      paramsRef.current = {
        current_page: currentPage,
        status: chartStatus ?
          OpeningStatusList.find(status => status.label === chartStatus)?.value
          : props.statusLabal,
      };

      // Call updateParams with the updated values
      updateParams({
        current_page: currentPage,
        status: paramsRef.current.status,
      });
    }
  }, [currentPage, props.statusLabal, updateParams]);


  const { newSeries, newLabals } = useMemo(() => {
    const newSeries = [];
    const newLabals = [];
    if (props && props.JobCountStatus) {
      if (props.JobCountStatus.Active) {
        newSeries.push(props.JobCountStatus.Active);
        newLabals.push("Active");
      }
      if (props.JobCountStatus.oh) {
        newSeries.push(props.JobCountStatus.oh);
        newLabals.push("On Hold");
      }
      if (props.JobCountStatus.I) {
        newSeries.push(props.JobCountStatus.I);
        newLabals.push("Interview");
      }
      if (props.JobCountStatus.rs) {
        newSeries.push(props.JobCountStatus.rs);
        newLabals.push("Resume Screening");
      }
      if (props.JobCountStatus.offer) {
        newSeries.push(props.JobCountStatus.offer);
        newLabals.push("Offer");
      }
      if (props.JobCountStatus.ri) {
        newSeries.push(props.JobCountStatus.ri);
        newLabals.push("Closed");
      }
    }
    return { newSeries, newLabals };
  }, [props.JobCountStatus]);

  const handlePageChange = useCallback((page) => {
    setCurrentPage(page);
  }, []);

  const data = useMemo(() => ({
    series: newSeries,
    options: {
      chart: {
        type: "donut",
        events: {
          dataPointSelection: (event, chartContext, config) => {
            const submissionStatus = OpeningStatusList.find((status) => {
              return (
                status.label === config.w.config.labels[config.dataPointIndex]
              );
            });
            setChartStatus(
              submissionStatus !== undefined
                ? submissionStatus.label
                : config.w.config.labels[config.dataPointIndex]
            );
          },
        },
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200,
            },
            legend: {
              position: "bottom",
            },
          },
        },
      ],
      labels: newLabals,
    },
  }), [newSeries, newLabals]);
  const fields = useMemo(() => ({
    opening_id: {
      header: "Opening id",
      formatter: (_fieldValue, record) => {
        return record?.opening_id;
      },
    },
    opening_title: {
      header: "Opening Title",
      formatter: (_fieldValue, record) => {
        return record?.opening_title;
      },
    },
    status: {
      header: "Status",
      formatter: (_fieldValue, record) => {
        const status = OpeningStatusList.find((status) => {
          return status.value === record.status;
        });
        return status ? status.label : record.status;
      },
    },
  }), []);

  const openingData = useCallback(() => (
    <>
      <Card title="JOB Data" bordered={false} className="px-0 py-0">
        <Table
          loading={loading}
          pagination={{
            total: openings?.totalRecords,
            showSizeChanger: false,
            onChange: handlePageChange,
          }}
          dataSource={openings?.job_opening_listing}
          columns={columns}
          exportableProps={{ fields, fileName: "JOB Data" }}
        />
      </Card>
    </>
  ), [loading, openings, columns, fields, handlePageChange]);

  if (error) return <div>Error: {error.message}</div>;

  return (
    <>
      <DoughnutChart loading={loading} data={data} newLabals={newLabals} newSeries={newSeries} />
      {openingData()}
    </>
  );
}

export default React.memo(JobReport);