import React from "react";
import { NavLink, useLocation } from "react-router-dom";
import { Card } from "antd";
import { useSelector } from "react-redux";

function ReportQuickLinks({ setTabChange }) {
  const users = useSelector(({ users }) => users);
  const role = users.role;
  const location = useLocation();
  const createNewClientPath = role === "admin" ? 'admin' : role === "recruitmentmanager" ? 'recruitment-manager' : role === "bdmmanager" ? 'bdm-manager' : role;

  const reportPaths = {
    JobReport: `/${createNewClientPath}/reports/JobReport`,
    CandidateReport: `/${createNewClientPath}/reports/candidateReport`,
    BDMJobReport: `/${createNewClientPath}/reports/bdmJobReport`,
    RecruiterJobReport: `/${createNewClientPath}/reports/recruiterJobReport`,
    FreelancerJobReport: `/${createNewClientPath}/reports/freelancerJobReport`,
    EmailReports: `/${createNewClientPath}/reports/emailReports`,
  };

  const handleNavLinkClick = (path) => {
    if (location.pathname !== path) {
      setTabChange();
    }
  };

  const renderNavLink = (path, text) => (
    <NavLink
      to={path}
      className="navi-link py-4 mb-2"
      activeClassName="active"
      onClick={() => handleNavLinkClick(path)}
    >
      <span className="navi-text font-size-lg">{text}</span>
    </NavLink>
  );

  return (
    <Card bordered={false} title="Quick Links">
      <div className="navi navi-bold navi-hover navi-active navi-link-rounded">
        <div className="navi-item mb-2">
          {renderNavLink(reportPaths.JobReport, "Job Report")}
          {renderNavLink(reportPaths.CandidateReport, "Candidate Report")}
          {renderNavLink(reportPaths.BDMJobReport, "BDM Report")}
          {renderNavLink(reportPaths.RecruiterJobReport, "Recruiter Report")}
          {renderNavLink(reportPaths.FreelancerJobReport, "Freelancer Recruiter Report")}
          {renderNavLink(reportPaths.EmailReports, "Email Reports")}
        </div>
      </div>
    </Card>
  );
}

export default React.memo(ReportQuickLinks);