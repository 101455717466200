import { Card, Col, List, Row, Spin } from 'antd'
import Chart from "react-apexcharts";
import React from 'react'

export const DoughnutChart = React.memo(({ loading, data, newLabals, newSeries }) => (

    <Spin spinning={loading}>
        <Card title={"All Jobs"}>
            <Row gutter={24}>
                <Col span={12}>
                    <div id="chart">
                        <Chart
                            options={data.options}
                            series={data.series}
                            type="donut"
                            width="380"
                        />
                    </div>
                </Col>
                <Col span={12}>
                    <List
                        dataSource={newLabals}
                        itemLayout={"horizontal"}
                        bordered={false}
                        renderItem={(item, index) => (
                            <List.Item
                                style={{ borderBottom: 0, padding: "5px 0" }}
                                key={index.toString()}
                            >
                                <Col span={18} style={{ textAlign: "right" }}>
                                    {item} :
                                </Col>
                                <Col span={6}>{newSeries[index]}</Col>
                            </List.Item>
                        )}
                    />
                </Col>
            </Row>
        </Card>
        <br />
    </Spin>
)
)
