import { Button, Card, Col, Input, Row, Spin, Switch, message } from "antd";
import React, { useEffect, useState } from "react";
import { showError } from "../../pages/utils";
import axios from "axios";
import { GET_BOOLEN_STRING, JOB_UPDATE } from "../../../ApiUrl";
import { requestHeader } from "../../../others/header";

export default function BooleanString({
  data,
  id,
  title = "",
  description = "",
}) {
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState({
    status: false,
    message: null,
  });
  const [booleanData, setBooleanData] = useState(null);
  const [error, setError] = useState({
    status: false,
    message: null,
  });
  const [getBooleanString, setGetBooleanString] = useState(false);

  const handleBooleanStringInput = (key, data) => {
    setBooleanData({ ...booleanData, [key]: data });
  };

  const updateBooleanString = async () => {
    setLoading(true);
    try {
      const updatedBooleanString = await axios.put(
        `${JOB_UPDATE}/${id}`,
        { boolean_string: booleanData },
        requestHeader()
      );

      setSuccess({
        status: true,
        message: "Job Openings updated successfully!",
      });
    } catch (error) {
      console.log(error);
      setError({
        status: true,
        message: "Job Openings updated Failed!",
      });
    } finally {
      setLoading(false);
      setTimeout(() => {
        setSuccess({
          status: false,
          message: null,
        });
        setError({
          status: false,
          message: null,
        });
      }, 3000);
    }
  };

  const handleBooleanString = (status) => {
    if (!status) {
      setGetBooleanString(false);
      return 1;
    }

    // const description = descEditor.getData()
    const plainString = description;
    const jobTitle = title;

    if (!jobTitle) {
      setGetBooleanString(false);
      setError({
        status: true,
        message: "Job opening title is required!",
      });

      setTimeout(() => {
        setErrorValue({
          status: false,
          message: null,
        });
      }, 3000);

      return 1;
    }

    if (!plainString) {
      setGetBooleanString(false);
      setError({
        status: true,
        message: "Job opening details is required!",
      });

      setTimeout(() => {
        setErrorValue({
          status: false,
          message: null,
        });
      }, 3000);

      return 1;
    }

    setGetBooleanString(true);
    setLoading(true);
  };

  const callGetBooleanString = () => {
    // const description = descEditor.getData()
    const plainString = description;
    const jobTitle = title;

    axios
      .post(
        GET_BOOLEN_STRING,
        {
          prompt: `
          Job title: ${jobTitle}
          job description: ${plainString}
          `,
        },
        requestHeader()
      )
      .then((response) => {
        console.log(response.data.data);
        setBooleanData(response.data.data);
        setSuccess({
          status: true,
          message: "Generate successfully",
        });

        setTimeout(() => {
          setSuccess({
            status: false,
            message: null,
          });
        }, 3000);
      })
      .catch((error) => {
        setError({
          status: true,
          message: "Something went wrong",
        });
        setTimeout(() => {
          setError({
            status: false,
            message: null,
          });
        }, 3000);
      })
      .finally((f) => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (getBooleanString) {
      callGetBooleanString();
    }
  }, [getBooleanString]);

  useEffect(() => {
    setBooleanData(data);
  }, [data]);

  return (
    <>
      <Card title="Boolean String" className="px-0 py-0">
        <Row gutter={24}>
          {booleanData ? (
            <Col span={20}>
              {[
                "boolean_string_1",
                "boolean_string_2",
                "boolean_string_3",
              ]?.map((string, idx) => (
                <div className="mt-5">
                  <Input
                    placeholder="Boolean String"
                    onChange={(e) => {
                      handleBooleanStringInput(string, e.target.value);
                    }}
                    value={booleanData[string]}
                  />
                </div>
              ))}
              <br />

              <Button onClick={updateBooleanString} type="primary">
                Save
              </Button>
              {loading && <Spin />}
              {showError(
                success.status,
                success.message,
                error.status,
                error.message
              )}
            </Col>
          ) : (
            <div>
              <span>{"Generate Boolean String: "}</span>
              <Switch
                checked={getBooleanString}
                onChange={handleBooleanString}
                style={{ marginLeft: "10px" }}
              />
              {loading && <Spin />}
            </div>
          )}
        </Row>
      </Card>
    </>
  );
}
