import React, { useState, useEffect, useCallback, useMemo } from "react";
import { Card, Select, Col, Row, Button, DatePicker } from "antd";
import { Redirect, Route, Switch } from "react-router-dom";
import ReportQuickLinks from "./ReportQuickLinks";
import { useSelector } from "react-redux";
import JobReport from "./JobReport";
import CandidateReport from "./CandidateReport";
import BDMJobReport from "./BDMJobReport";
import RecruiterJobReport from "./RecruiterJobReport";
import FreelancerJobReport from "./FreelancerJobReport";
import { ADMIN_REPORT_COUNT, ALL_JOBS_EMAIL_REPORT, BDM_REPORT_COUNT, FREELANCE_JOB_LIST, FREELANCE_REPORT_COUNT, JOB_BDM_LIST, JOB_OPENINGS, JOB_RECRUITER_LIST, JOBS_LIST, JOBS_LIST_BDM, RECRUITER_REPORT_COUNT, GET_ALL_JOB_OPENINGS_NAMES } from "../../../../../../ApiUrl";
import axios from "axios";
import { emailStatus, OpeningStatusList } from "../../../../../pages/constant/constant";
import useReportData from "../../../../../../hooks/useReportData";
import { useLocation } from "react-router-dom";
import EmailReports from "./EmailReports";

const { RangePicker } = DatePicker;
const { Option } = Select;

function Reports() {
    const [filters, setFilters] = useState({
        status: "",
        dateRange: [],
        dateRangeValue: [],
        selectedBDM: "",
        selectedRecruiter: "",
        selectedJobOpening: "",
    });
    const [allBDM, setAllBDM] = useState([]);
    const [recruiterList, setAllRecruiterList] = useState([]);
    const [activeTab, setActiveTab] = useState("");

    const users = useSelector(({ users }) => users);
    const role = users.role;
    const createNewClientPath = useMemo(() => {
        switch (role) {
            case "admin": return 'admin';
            case "recruitmentmanager": return 'recruitment-manager';
            case "bdmmanager": return 'bdm-manager';
            default: return role;
        }
    }, [role]);

    const location = useLocation();

    const reportPaths = useMemo(() => ({
        JobReport: `/${createNewClientPath}/reports/JobReport`,
        CandidateReport: `/${createNewClientPath}/reports/candidateReport`,
        BDMJobReport: `/${createNewClientPath}/reports/bdmJobReport`,
        RecruiterJobReport: `/${createNewClientPath}/reports/recruiterJobReport`,
        FreelancerJobReport: `/${createNewClientPath}/reports/freelancerJobReport`,
        EmailReport: `/${createNewClientPath}/reports/emailReports`,
    }), [createNewClientPath]);

    const initialParams = useMemo(() => ({
        dateRange: [],
        categories: [],
        status: '',
        opening_id: '',
        company_id: '',
    }), []);

    const transformParams = useCallback((params) => ({
        ...params,
        status: params.status === '' ? undefined : params.status,
        recruiter_id: filters.selectedRecruiter,
        bdm_id: filters.selectedBDM,
    }), [filters.selectedRecruiter, filters.selectedBDM, filters.status]);

    const { data: adminReport, loading: adminLoading, updateParams: updateAdminParams } = useReportData(ADMIN_REPORT_COUNT, initialParams, transformParams);
    const { data: bdmReport, loading: bdmLoading, updateParams: updateBdmParams } = useReportData(BDM_REPORT_COUNT, initialParams, transformParams);
    const { data: recruiterReport, loading: recruiterLoading, updateParams: updateRecruiterParams } = useReportData(RECRUITER_REPORT_COUNT, initialParams, transformParams);
    const { data: freelanceReport, loading: freelanceLoading, updateParams: updateFreelanceParams } = useReportData(FREELANCE_REPORT_COUNT, initialParams, transformParams);
    // const {data:emailReport, loading:emailLoading, updateParams: updateEmailParams} = useReportData(ALL_JOBS_EMAIL_REPORT, initialParams, transformParams);

    const isLoading = adminLoading || bdmLoading || recruiterLoading || freelanceLoading;


    useEffect(() => {
        const params = {
            dateRange: filters.dateRange,
            status: filters.status,
            opening_id: '',
            company_id: '',
            bdm_id: filters.selectedBDM,
            recruiter_id: filters.selectedRecruiter // fixed typo here
        };
        updateAdminParams(params);
        updateBdmParams(params);
        updateRecruiterParams(params);
        updateFreelanceParams(params);
        // updateEmailParams(params);
    }, [filters, updateAdminParams, updateBdmParams, updateRecruiterParams, updateFreelanceParams]);

    useEffect(() => {
        if (location.pathname === reportPaths.BDMJobReport) {
            setActiveTab("BDM");
            getAllBDMList();
        } else if (location.pathname === reportPaths.RecruiterJobReport) {
            setActiveTab("Recruiter");
            getAllRecruiterList();
        } else if (location.pathname === reportPaths.EmailReport) {
            setActiveTab("Email");
            handleFilterChange("status", "Active");
        } 
        
        else {
            setActiveTab("");
        }
    }, [location.pathname, reportPaths.BDMJobReport, reportPaths.RecruiterJobReport]);

    const getAllBDMList = useCallback(() => {
        axios.get(JOB_BDM_LIST, { headers: { Authorization: users.token } })
            .then((res) => setAllBDM(res.data.data))
            .catch((error) => console.error("Error fetching BDM list:", error));
    }, [users.token]);

    const getAllRecruiterList = useCallback(() => {
        axios.get(JOB_RECRUITER_LIST, { headers: { Authorization: users.token } })
            .then((res) => setAllRecruiterList(res.data.data))
            .catch((error) => console.error("Error fetching Recruiter list:", error));
    }, [users.token]);


    const handleFilterChange = useCallback((key, value) => {
        setFilters(prev => ({ ...prev, [key]: value }));
    }, []); // removed unnecessary dependencies

    const onResetFilters = useCallback(() => {
        setFilters({
            status: "",
            dateRange: [],
            dateRangeValue: [],
            selectedBDM: "",
            selectedRecruiter: "",
            selectedJobOpening: "",
        });
    }, []);

    const handleTabChange = useCallback(() => {
        onResetFilters();
    }, [onResetFilters]);

    const filtersUI = useMemo(() => (
        <Card title="Filters" bordered={false} className="px-0 py-0">
            <Row gutter={24}>
                <Col span={8} sm={16} xl={10}>
                    <label htmlFor="by_source" className="" title="Date Range">
                        Date Range :
                    </label>
                    <br />
               <RangePicker
                        allowClear={true}
                        key={"RangePicker"}
                        value={filters.dateRangeValue}
                        onChange={(value, dateString) => {
                            handleFilterChange("dateRangeValue", value);
                            handleFilterChange("dateRange", dateString);
                        }}
                    />
                </Col>
                {activeTab !== "Candidate" && activeTab !=="Email"  && (
                    <Col span={6}>
                        <label htmlFor="by_source" className="" title="Status">
                            Status :
                        </label>
                        <br />
                        <Select
                            placeholder="Select Status Name"
                            value={filters.status}
                            onChange={(value) => handleFilterChange("status", value)}
                            style={{ width: "100%" }}
                        >
                            <Option value={""}>{"Select"}</Option>
                            {OpeningStatusList.map((status, index) => (
                                <Option key={index.toString()} value={status.value}>
                                    {status.label}
                                </Option>
                            ))}
                        </Select>
                    </Col>
                )}
                {activeTab === "BDM" && (
                    <Col span={6}>
                        <label htmlFor="by_source" className="" title="Status">
                            BDM :
                        </label>
                        <br />
                        <Select
                            value={filters.selectedBDM}
                            onChange={(value) => handleFilterChange("selectedBDM", value)}
                            style={{ width: "100%" }}
                        >
                            <Option value={""}>{"Select"}</Option>
                            {allBDM.map((user, index) => (
                                <Option value={user._id} key={index.toString()}>
                                    {user.display_name.charAt(0).toUpperCase() + user.display_name.slice(1)}
                                </Option>
                            ))}
                        </Select>
                    </Col>
                )}
                {activeTab === "Recruiter" && (
                    <Col span={6}>
                        <label htmlFor="by_source" className="" title="Status">
                            Recruiter :
                        </label>
                        <br />
                        <Select
                            placeholder="Select Recruiter Name"
                            value={filters.selectedRecruiter}
                            onChange={(value) => handleFilterChange("selectedRecruiter", value)}
                            style={{ width: "100%" }}
                        >
                            <Option value={""}>{"Select"}</Option>
                            {recruiterList.map((status, index) => (
                                <Option key={index.toString()} value={status._id}>
                                    {status.display_name}
                                </Option>
                            ))}
                        </Select>
                    </Col>
                )}
                {
                    activeTab === "Email" &&(
                       
                        <Col span={6}>
                        <label htmlFor="by_source" className="" title="Status">
                            Status :
                        </label>
                        <br />
                        <Select
                            placeholder="Select Status Name"
                            value={filters.status}
                            defaultValue={"Active"}
                            
                            onChange={(value) => handleFilterChange("status", value)}
                            style={{ width: "100%" }}
                        >
                            <Option value={""} >{"Select"}</Option>
                            {OpeningStatusList.map((status, index) => (
                                <Option key={index.toString()} value={status.value}>
                                    {status.label}
                                </Option>
                            ))}
                        </Select>
                    </Col>
                       

                    )
                }
            </Row>
            <Row gutter={24} className="py-5 text-right">
                <Col span={24}>
                    <Button onClick={onResetFilters} type="primary" size="small" danger>
                        Clear Filters
                    </Button>
                </Col>
            </Row>
        </Card>
    ), [filters, activeTab, allBDM, recruiterList, handleFilterChange, onResetFilters]);

    return (
        <div>
            <Row gutter={24}>
                <Col span={6}>
                    <ReportQuickLinks setTabChange={handleTabChange} />
                </Col>
                <Col span={18}>
                    {filtersUI}
                    <Switch>
                        <Redirect
                            from={`/${createNewClientPath}/reports`}
                            exact={true}
                            to={reportPaths.JobReport}
                        />
                        <Route
                            path={reportPaths.JobReport}
                            render={() => (
                                <JobReport
                                    JobCountStatus={adminReport?.JobCountStatus}
                                    arrayDateRange={filters.dateRange}
                                    statusLabal={filters.status}
                                    bdm_id={filters.selectedBDM}
                                    recruiter_id={filters.selectedRecruiter}
                                />
                            )}
                        />
                        <Route
                            path={reportPaths.CandidateReport}
                            render={() => (
                                <CandidateReport
                                    candidateJobCount={adminReport?.candidateJobCount}
                                    arrayDateRange={filters.dateRange}
                                    statusLabal={filters.status}
                                />
                            )}
                        />
                        <Route
                            path={reportPaths.BDMJobReport}
                            render={() => (
                                <BDMJobReport
                                    bdmJobCount={bdmReport?.bdmJobCount}
                                    arrayDateRange={filters.dateRange}
                                    statusLabal={filters.status}
                                    bdm_id={filters.selectedBDM}
                                    recruiter_id={filters.selectedRecruiter}
                                />
                            )}
                        />
                        <Route
                            path={reportPaths.RecruiterJobReport}
                            render={() => (
                                <RecruiterJobReport
                                    recruiterJobCount={recruiterReport?.recruiterJobCount}
                                    arrayDateRange={filters.dateRange}
                                    statusLabal={filters.status}
                                    bdm_id={filters.selectedBDM}
                                    recruiter_id={filters.selectedRecruiter}
                                />
                            )}
                        />
                        <Route
                            path={reportPaths.FreelancerJobReport}
                            render={() => (
                                <FreelancerJobReport
                                    freelanceJobCount={freelanceReport?.freelanceJobCount}
                                    arrayDateRange={filters.dateRange}
                                    statusLabal={filters.status}
                                    bdm_id={filters.selectedBDM}
                                    recruiter_id={filters.selectedRecruiter}
                                />
                            )}
                        />
                        <Route
                            path={reportPaths.EmailReport}
                            render={() => (
                                <EmailReports
                                    // emailReport={emailReport}
                                    // emailReportCount={emailReport?.emailReportCount}
                                    arrayDateRange={filters.dateRange}
                                    statusLabal={filters.status}
                                    bdm_id={filters.selectedBDM}
                                    recruiter_id={filters.selectedRecruiter}
                                    selectedJobOpening={filters.selectedJobOpening}
                                />
                            )}
                        />
                    </Switch>
                </Col>
            </Row>
        </div>
    );
}

export default React.memo(Reports);
