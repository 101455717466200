import React, { useState, useEffect, useCallback } from 'react';
import { Button, Dropdown, Menu, Input, Select, Space } from 'antd';
import { ArrowDownOutlined, ArrowUpOutlined, DownOutlined, SortAscendingOutlined, SortDescendingOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { setSortBy, setSortOrder, setPage, setLocationSearch, setLabelFilter, fetchCandidates } from '../../../../redux/reducers/candidatesSlice';

const { Option } = Select;
import { debounce } from 'lodash';
export default function CandidateSort({ jobId }) {
    const dispatch = useDispatch();
    const { currentListId, sortBy, sortOrder, page, pageSize, search, locationSearch, labelFilter } = useSelector((state) => state.candidates);
    const [locationSearchLocal, setLocationSearchLocal] = useState(locationSearch);
    const [labelFilterLocal, setLabelFilterLocal] = useState(labelFilter);
    const handleSort = (value) => {
        if (sortBy === value) {
            dispatch(setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc'));
        } else {
            dispatch(setSortBy(value));
            dispatch(setSortOrder('asc'));
        }
        dispatch(setPage(1));
        dispatch(fetchCandidates({
            jobId,
            page: 1,
            pageSize,
            sortBy: value,
            sortOrder: sortBy === value ? (sortOrder === 'asc' ? 'desc' : 'asc') : 'asc',
            shortlistId: currentListId,
            locationSearch,
            labelFilter,
        }));
    };



    const debouncedLocationSearch = useCallback(
        debounce((value) => {
            dispatch(setLocationSearch(value));
            dispatch(fetchCandidates({
                jobId,
                page: 1,
                pageSize,
                search,
                sortBy,
                sortOrder,
                shortlistId: currentListId,
                locationSearch: value,
                labelFilter,
            }));
        }, 300),
        [dispatch, jobId, pageSize, search, sortBy, sortOrder, currentListId, labelFilter]
    );

    const handleLocationSearch = (e) => {
        const value = e.target.value;
        setLocationSearchLocal(value);
        debouncedLocationSearch(value);
    };

    useEffect(() => {
        setLocationSearchLocal(locationSearch);
    }, [locationSearch]);


    const handleLabelFilter = (value) => {
        setLabelFilterLocal(value);
        dispatch(setLabelFilter(value));
        dispatch(fetchCandidates({
            jobId,
            page: 1,
            pageSize,
            search,
            sortBy,
            sortOrder,
            shortlistId: currentListId,
            locationSearch,
            labelFilter: value,
        }));
    };
    const menuItems = [
        {
            key: 'sort',
            type: 'group',
            label: 'Sort Options',
            children: [
                {
                    key: 'verifiedNumber',
                    label: (
                        <span className="flex items-center justify-between w-full">
                            Verified Number
                            {sortBy === 'verifiedNumber' && (sortOrder === 'asc' ? <ArrowUpOutlined className="w-4 h-4" /> : <ArrowDownOutlined className="w-4 h-4" />)}
                        </span>
                    ),
                    onClick: () => handleSort('verifiedNumber'),
                },
                {
                    key: 'verifiedEmail',
                    label: (
                        <span className="flex items-center justify-between w-full">
                            Verified Email
                            {sortBy === 'verifiedEmail' && (sortOrder === 'asc' ? <ArrowUpOutlined className="w-4 h-4" /> : <ArrowDownOutlined className="w-4 h-4" />)}
                        </span>
                    ),
                    onClick: () => handleSort('verifiedEmail'),
                },
            ],
        },
        {
            type: 'divider',
        },
        {
            key: 'location',
            type: 'group',
            label: 'Location',
            children: [
                {
                    key: 'locationSearch',
                    label: (
                        <Input
                            placeholder="Search location"
                            value={locationSearchLocal}
                            onChange={handleLocationSearch}
                            onPressEnter={handleLocationSearch}
                            onClick={(e) => e.stopPropagation()}
                        />
                    ),
                },
            ],
        },
        {
            type: 'divider',
        },
        {
            key: 'label',
            type: 'group',
            label: 'Label',
            children: [
                {
                    key: 'labelFilter',
                    label: (
                        <Select
                            style={{ width: '100%' }}
                            placeholder="Select Label"
                            value={labelFilterLocal}
                            onChange={handleLabelFilter}
                            onClick={(e) => e.stopPropagation()}
                            onMouseDown={(e) => e.stopPropagation()}
                            dropdownStyle={{ zIndex: 2000 }}
                        >
                            <Option value="">All</Option>
                            <Option value="Linkedin">LinkedIn</Option>
                            <Option value="Indeed">Indeed</Option>
                        </Select>
                    ),
                },
            ],
        },
    ]

    const sortMenu = (
        <Menu onClick={(e) => e.domEvent.stopPropagation()}>
            <Menu.ItemGroup title="Sort Options">
                {/* <Menu.Item key="linkedin" onClick={() => handleSort('linkedin')}>
                    LinkedIn {sortBy === 'linkedin' && (sortOrder === 'asc' ? <SortAscendingOutlined /> : <SortDescendingOutlined />)}
                </Menu.Item> */}
                <Menu.Item key="verifiedNumber" onClick={() => handleSort('verifiedNumber')}>
                    Verified Number {sortBy === 'verifiedNumber' && (sortOrder === 'asc' ? <SortAscendingOutlined /> : <SortDescendingOutlined />)}
                </Menu.Item>
                <Menu.Item key="verifiedEmail" onClick={() => handleSort('verifiedEmail')}>
                    Verified Email {sortBy === 'verifiedEmail' && (sortOrder === 'asc' ? <SortAscendingOutlined /> : <SortDescendingOutlined />)}
                </Menu.Item>
            </Menu.ItemGroup>
            <Menu.Divider />
            <Menu.ItemGroup title="Location">
                <Menu.Item key="location">
                    <Input
                        placeholder="Search location"
                        value={locationSearchLocal}
                        onChange={(e) => {
                            e.stopPropagation();
                            handleLocationSearch(e);
                        }}
                        onPressEnter={(e) => {
                            e.stopPropagation();
                            handleLocationSearch(e);
                        }}
                        onClick={(e) => e.stopPropagation()}
                    />
                </Menu.Item>
            </Menu.ItemGroup>
            <Menu.Divider />
            <Menu.ItemGroup title="Label">
                <Menu.Item key="label" onClick={(e) => e.domEvent.stopPropagation()}>
                    <Select
                        style={{ width: '100%' }}
                        placeholder="Select Label"
                        value={labelFilterLocal}
                        onChange={(value) => {
                            handleLabelFilter(value);
                        }}
                        onClick={(e) => e.stopPropagation()}
                        onMouseDown={(e) => e.stopPropagation()}
                        dropdownStyle={{ zIndex: 2000 }}
                    >
                        <Option value="">All</Option>
                        <Option value="Linkedin">LinkedIn</Option>
                        <Option value="Indeed">Indeed</Option>
                    </Select>
                </Menu.Item>
            </Menu.ItemGroup>
        </Menu>
    );
    return (
        <Space>
            <Dropdown
                // overlay={sortMenu}
                menu={{ items: menuItems }}
                trigger={['click']}>
                <Button>
                    Sort & Filter <DownOutlined />
                </Button>
            </Dropdown>
        </Space>
    );
}