import { message } from 'antd';
import React from 'react';

class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(error) {
        return { hasError: true };
    }

    componentDidCatch(error, errorInfo) {
        console.log(error, errorInfo);
        // You can log the error to an error reporting service here
        message.error("Something went wrong. Please refresh the page.");
    }

    handleRefresh = () => {
        window.location.reload();
    }
    render() {
        if (this.state.hasError) {
            return <div>
                <h1>Something went wrong.</h1>
                <button onClick={this.handleRefresh}>Refresh Page</button>
            </div>;
        }

        return this.props.children;
    }
}
export default ErrorBoundary;