import React, { useEffect, useState } from 'react'
import { ALL_JOBS_EMAIL_REPORT } from '../../../../../../ApiUrl'
import { emailStatus } from '../../../../../pages/constant/constant'
import useReportData from '../../../../../../hooks/useReportData'
import Chart from "react-apexcharts"
import { Card, Row, Col, List, Spin } from "antd"
import { Table } from "ant-table-extensions"
import JobEmailHistoryModal from '../../../../Openings/JobEmailHistoryModal'
import { InfoCircleOutlined } from '@ant-design/icons'

export default function EmailReports(props) {
  const [currentPage, setCurrentPage] = useState(1)
  const [chartStatus, setChartStatus] = useState("")
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedOpeningId, setSelectedOpeningId]=useState(null)
  const initialParams = {
    current_page: currentPage,
    per_page: 10,
    sort_order: 'desc',
    filter_value: '',
    order: 'created_at',
    dateRange: props.arrayDateRange,
    categories: [],
    status: props.statusLabal,
    company_id: '',
    bdm_id: props.bdm_id,
    recruiter_id: props.recruiter_id,
    opening_id: props.selectedJobOpening,
  }

  const { data: emailReports, loading, error, updateParams } = useReportData(ALL_JOBS_EMAIL_REPORT, initialParams)
  useEffect(() => {
    updateParams({
      current_page: currentPage,
      status: chartStatus ? emailStatus.find(status => status === chartStatus) : props.statusLabal,
      bdm_id: props.bdm_id,
      recruiter_id: props.recruiter_id,
      opening_id: props.selectedJobOpening,
      dateRange: props.arrayDateRange 
    })
  }, [currentPage, chartStatus, props.statusLabal, props.bdm_id, props.recruiter_id, props.selectedJobOpening, props.arrayDateRange]) 
  const handleEmailClick = (opening_id) => {
    setSelectedOpeningId(opening_id);
    setModalVisible(true);
  };

  const renderClickableCount = (count, opening_id) => (
    <span style={{ cursor: 'pointer' }}>
      {count} <InfoCircleOutlined  onClick={() => handleEmailClick(opening_id)} />
    </span>
  );
  const columns = [
    {
      title: "Opening Title",
      dataIndex: "job_title",
      key: "job_title",
    },
    {
      title: "Opening ID",
      dataIndex: "opening_id",
      key: "opening_id",
    },
    {
      title: "Total Emails",
      dataIndex: "totalEmails",
      key: "totalEmails",
      render: (text, record) => renderClickableCount(text, record.opening_id)
    },
    {
      title: "Sent",
      dataIndex: "sent",
      key: "sent",
    },
    {
      title: "Opened",
      dataIndex: "opened",
      key: "opened",
    },
    {
      title: "Replied",
      dataIndex: "replied",
      key: "replied",
    },
    {
      title: "Bounced",
      dataIndex: "bounced",
      key: "bounced",
    },  
    {
      title:"Status",
      dataIndex:"status",
      key:"status",
    }

  ]

  const chartData = emailReports?.emailReportCount ? {
    series: [
      emailReports.emailReportCount.sent,
      emailReports.emailReportCount.opened,
      emailReports.emailReportCount.replied,
      emailReports.emailReportCount.bounced
    ],
    options: {
      chart: {
        type: "donut",
        events: {
          dataPointSelection: function(event, chartContext, config) {
            const selectedStatus = ["Sent", "Opened", "Replied", "Bounced"][config.dataPointIndex]
            setChartStatus(selectedStatus)
          }
        }
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200,
            },
            legend: {
              position: "bottom",
            },
          },
        },
      ],
      labels: ["Sent", "Opened", "Replied", "Bounced"],
    }
  } : null

  const handlePageChange = (page) => {
    setCurrentPage(page)
  }
  const DoughnutChart = () => (
    <Spin spinning={loading}>
      <Card title="Email Report">
        {chartData && (
          <Row gutter={24}>
            <Col span={12}>
              <div id='chart'>
                <Chart
                  options={chartData.options}
                  series={chartData.series}
                  type="donut"
                  width="380"
                />
              </div>
            </Col>
            <Col span={12}>
              <List
                dataSource={chartData.options.labels}
                itemLayout='horizontal'
                bordered={false}
                renderItem={(item, index) => (
                  <List.Item style={{ borderBottom: 0, padding: "5px 0" }} key={index.toString()}>
                    <Col span={18} style={{ textAlign: "right" }}>
                      {item}:
                    </Col>
                    <Col span={6}>{chartData.series[index]}</Col>
                  </List.Item>
                )}
              />
            </Col>
          </Row>
        )}
      </Card>
      <br />
    </Spin>
  )

  const EmailTable = () => (
    <Card title="Email Record" bordered={false} className='px-0 py-0'>
      <Table 
        pagination={{
          current: emailReports?.currentPage || 1,
          total: emailReports?.totalRecords || 0,
          pageSize: 10,
          onChange: handlePageChange, 
        }}
        dataSource={emailReports?.emailReportsList || []}
        columns={columns}
        // exportableProps={{
        //   fields: columns.reduce((acc, col) => ({ ...acc, [col.dataIndex]: { header: col.title } }), {}),
        //   fileName: "Email Report"
        // }}
        exportableProps={{
          fields: {
            job_title: {
              header: "Opening Title",
              formatter: (_fieldValue, record) => record?.job_title,
            },
            opening_id: {
              header: "Opening ID",
              formatter: (_fieldValue, record) => record?.opening_id,
            },
            totalEmails: {
              header: "Total Emails",
              formatter: (_fieldValue, record) => record?.totalEmails,
            },
            sent: {
              header: "Sent",
              formatter: (_fieldValue, record) => record?.sent,
            },
            opened: {
              header: "Opened",
              formatter: (_fieldValue, record) => record?.opened,
            },
            replied: {
              header: "Replied",
              formatter: (_fieldValue, record) => record?.replied,
            },
            bounced: {
              header: "Bounced",
              formatter: (_fieldValue, record) => record?.bounced,
            },
            status: {
              header: "Status",
              formatter: (_fieldValue, record) => record?.status,
            },
          },
          fileName: "Email Report"
        }}
      />
    </Card>
  )

  if (error) return <div>Error: {error.message}</div> 
  if (loading) return <Spin spinning={loading} />

  return (
    <>
   

        
   <JobEmailHistoryModal
        openingId={selectedOpeningId}
        visible={modalVisible}
        onClose={() => {
          setModalVisible(false);
          setSelectedOpeningId(null);
        }}
      />

      {DoughnutChart()}
      {EmailTable()}
    </>
  )
}