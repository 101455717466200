import { useState, useEffect, useRef, useCallback } from 'react';
import axios from 'axios';
import { useSelector } from 'react-redux';

const useReportData = (apiUrl, initialParams, transformParams = (params) => params) => {
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [params, setParams] = useState(initialParams);

    const users = useSelector(({ users }) => users);
    const paramsRef = useRef(params);
    const transformParamsRef = useRef(transformParams);

    useEffect(() => {
        paramsRef.current = params;
        transformParamsRef.current = transformParams;
    }, [params, transformParams]);

    const fetchData = useCallback(async () => {
        let isMounted = true;
        setLoading(true);
        try {
            const response = await axios.post(
                apiUrl,
                transformParamsRef.current(paramsRef.current),
                {
                    headers: { Authorization: users.token },
                }
            );
            if (isMounted) {
                setData(response.data.data);
                setError(null);
            }
        } catch (err) {
            if (!axios.isCancel(err) && isMounted) {
                setError(err);
            }
        } finally {
            if (isMounted) {
                setLoading(false);
            }
        }
        return () => {
            isMounted = false;
        };
    }, [apiUrl, users.token]);

    useEffect(() => {
        const controller = new AbortController();
        const debounceTimer = setTimeout(() => {
            fetchData();
        }, 500);

        return () => {
            controller.abort();
            clearTimeout(debounceTimer);
        };
    }, [fetchData, params]);

    const updateParams = useCallback((newParams) => {
        setParams((prevParams) => {
            const updatedParams = { ...prevParams, ...newParams };
            if (newParams.status || newParams.dateRange) {
                updatedParams.page = 1;
            }
            return updatedParams;
        });
    }, []);

    return { data, loading, error, updateParams, refetch: fetchData };
};

export default useReportData;