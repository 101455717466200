/* eslint-disable no-lone-blocks */
import React, { useState } from "react";
import axios from "axios";
import { NavLink } from "react-router-dom";
import { Table, Card, Button, Popconfirm, Spin, Modal, Row, Col } from "antd";
import { connect } from "react-redux";
import {
  EditOutlined,
  DeleteOutlined,
  LoadingOutlined,
  EyeOutlined,
  LinkedinOutlined,
} from "@ant-design/icons";
import { getFormatDate, showError } from "../../pages/utils";
import {
  GET_AUTO_SOURCE_LIST,
  CONTACTS_ADMIN,
  GET_AUTO_SOURCE_DELETE,
} from "../../../ApiUrl";

const ViewAutoSource = ({ data }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = (e) => {
    console.log(e);
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleKey = {
    id: "ID",
    name: "Name",
    sub_title: "Sub Title",
    location_industry: "Location Industry",
    history: "History",
    company: "Company",
    link: "Link",
    email: "Email",
    phone: "Phone",
  };

  const generateView = () => {
    let viewData = [];
    for (let i in data) {
      switch (i) {
        case "id":
          continue;
        case "link":
          viewData.push(
            <Row style={{ padding: "15px 0" }}>
              <Col span={6}>
                <b>{handleKey[i]}:</b>
              </Col>
              <Col span={18}>
                <a href={data[i]} target="_blank">
                  <LinkedinOutlined fontSize={50} />
                  Visit Profile
                </a>
              </Col>
            </Row>
          );
          continue;
        // case "phone":
        //   viewData.push(
        //     <Row style={{ padding: "15px 0" }}>
        //       <Col span={6}>
        //         <b>{handleKey[i]}:</b>
        //       </Col>
        //       <Col span={18}>{data[i]}</Col>
        //     </Row>
        //   );
        //   continue;
      }
      viewData.push(
        <Row style={{ padding: "15px 0" }}>
          <Col span={6}>
            <b>{handleKey[i]}:</b>
          </Col>
          <Col span={18}>{data[i]}</Col>
        </Row>
      );
    }
    return viewData;
  };


  return (
    <>
      <Button
        type="text"
        onClick={showModal}
        icon={<EyeOutlined style={{ color: "green" }} />}
      />
      <Modal
        title="Auto source data"
        open={isModalOpen}
        onOk={handleOk}
        // style8={{width:"100px", display:"block"}}
        width={600}
        onCancel={handleCancel}
        footer={[
          <Button key="ok" type="primary" onClick={handleOk}>
            OK
          </Button>,
        ]}
      >
        {generateView()}
      </Modal>
    </>
  );
};

class AutoSourcingList extends React.Component {
  columns1 = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Sub Title",
      dataIndex: "sub_title",
      key: "sub_title",
    },
    {
      title: "Company",
      dataIndex: "company",
      key: "company",
    },
    {
      title: "LinkedIn",
      dataIndex: "link",
      key: "link",
      render: (file) => {
        return file ? (
          <a href={file} target="_blank" download={""}>
            Visit
          </a>
        ) : (
          ""
        );
        //  return date ? date : '';
      },
    },
    {
      title: "Action",
      dataIndex: "id",
      key: "id",
      render: (aa, id) => (
        <>
          <Popconfirm
            title="Are you sure you want to delete？"
            onConfirm={() => this.deleteItem(id)}
            icon={<DeleteOutlined style={{ color: "red" }} />}
          >
            <Button
              type="text"
              icon={<DeleteOutlined style={{ color: "red" }} />}
              onClick={(e) => {
                console.log(e.target);
              }}
            />
          </Popconfirm>
          {/* {this.getDataById(id)} */}
          <ViewAutoSource data={this.getDataById(id)} />
        </>
      ),
    },
  ];

  //candidate_submission_details
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      contacts: [],
      order_direction: "desc",
      page: 1,
      per_page: 10,
    };
  }

  getContactList = () => {
    axios
      .post(
        GET_AUTO_SOURCE_LIST,
        {
          user_id: this.props.users.user._id,
          current_page: this.state.page,
          per_page: this.state.per_page,
          order_direction: this.state.order_direction,
          search: "",
          order: "created_at",
        },
        {
          headers: { Authorization: this.props.token },
        }
      )
      .then((res) => {
        this.setState({ contacts: res.data.data });

        console.log(res.data)

        this.setState({
          paginate: {
            totalRecords: res.data.totalRecords,
            totalPages: res.data.totalPages,
          },
        });
      })
      .catch((error) => { });
  };

  componentDidMount() {
    this.getContactList();
  }

  deleteItem = (record) => {
    if (record != undefined) {
      axios
        .delete(
          GET_AUTO_SOURCE_DELETE +
          "?lead_id=" +
          record.id +
          "&user_id=" +
          this.props.users.user._id,
          {
            headers: { Authorization: this.props.users.token },
          }
        )
        .then((res) => {
          if (!res.data.error) {
            this.setState({
              success: true,
              loading: false,
              error: false,
              successMessage: res.data.message,
              errorMessage: "",
            });
            setTimeout(() => {
              this.setDefaultState();
              this.getContactList();
            }, 3000);
          }
        })
        .catch((error) => {
          let errorMessage = "";
          {
            Object.entries(error.response.data.errors).map(([key, value]) => {
              return (errorMessage += value + ", ");
            });
          }
          this.setState({
            error: true,
            success: false,
            loading: false,
            successMessage: "",
            errorMessage: errorMessage,
          });
          this.setDefaultState();
        });
    }
  };

  setDefaultState = () => {
    setTimeout(() => {
      this.setState({
        error: false,
        success: false,
        loading: false,
        successMessage: "",
        errorMessage: "",
      });
    }, 3000);
  };

  handlePaginationChange = (current, pageSize) => {
    // Update the page and pageSize state variables
    console.log("page: ", current);
    this.setState({ page: current });
    this.setState(
      { page: current, pageSize },

      () => this.getContactList() // Fetch data for the new page
    );
  };

  getDataById = (id) => {
    const row = this.state.contacts.paginatedResults.find(
      (value) => value == id
    );
    console.log(id);
    return row;
  };

  render() {
    const _this = this;
    const contact_list_details = this.state.contacts.paginatedResults;
    console.log(contact_list_details);

    const antIcon = <LoadingOutlined style={{ fontSize: 32 }} spin />;
    const isCompanyProfileEditable =
      this.props.users.user.contact_person_details === undefined ? false : true;
    const role = this.props.role;
    const rolePath = role === "admin" ? "admin" : role === "bdmmanager" ? "bdm-manager" : role === "operations" ? "operations" : role === "recruitmentmanager" ? "recruitment-manager" : role;

    return (
      <div>
        <Spin indicator={antIcon} spinning={this.state.loading}>
          <Card
            title="Auto Source List"
            bordered={false}
            className="px-0 py-0"
            extra={
              isCompanyProfileEditable === false ? (
                <NavLink to={`/${rolePath}/autosource-new`}>
                  <Button type="primary">Add New</Button>
                </NavLink>
              ) : null
            }
          >
            {showError(
              this.state.success,
              this.state.successMessage,
              this.state.error,
              this.state.errorMessage
            )}
            <div className="row">
              <div className="col-lg-12">
                <div className={`card card-custom card-stretch gutter-b`}>
                  <div className="card-body py-3 px-3">
                    <Table
                      pagination={{
                        total: this.state.paginate?.totalRecords,
                        showSizeChanger: false,
                        current: this.state.page, // Set current page from state
                        pageSize: this.state.per_page, // Set page size from state
                        onChange: this.handlePaginationChange, // Use custom method to handle pagination change
                      }}
                      dataSource={contact_list_details}
                      columns={this.columns1}
                    />
                  </div>
                </div>
              </div>
            </div>
          </Card>
        </Spin>
      </div>
    );
  }
}

// Map Redux state to React component props
const mapStateToProps = (state) => {
  return {
    token: state.users.token,
    role: state.users.role,
    users: state.users,
  };
};

export default connect(mapStateToProps)(AutoSourcingList);
