import React, { useEffect } from "react";
import { Provider } from "react-redux";
import { BrowserRouter, useHistory } from "react-router-dom";
import { IntlProvider } from "react-intl";
import { PersistGate } from "redux-persist/integration/react";
import { Routes } from "../App/Routes";
import {
  LayoutSplashScreen,
  BlueBixThemeProvider,
} from "../_theme_parts/layout";
//import "antd/dist/antd.css";
import 'antd/dist/antd.min.css';
import ErrorBoundary from "./pages/Candidate/components/ErrorBoundary";

const APP_VERSION = process.env.REACT_APP_VERSION || '1.0.0';
export default function App({ store, basename, persistor }) {
  // moment.tz.setDefault("Asia/Kolkata");
  const APP_VERSION_KEY = "bix_app_version"

  useEffect(() => {
    const storedVersion = localStorage.getItem(APP_VERSION_KEY);
    if (storedVersion !== APP_VERSION) {
      localStorage.clear();
      localStorage.setItem(APP_VERSION_KEY, APP_VERSION);
      window.location.reload();
    }
  }, []);
  let history = useHistory();
  return (
    <IntlProvider locale="en">
      <Provider store={store}>
        <PersistGate persistor={persistor} loading={<LayoutSplashScreen />}>
          <React.Suspense fallback={<LayoutSplashScreen />}>
            <BrowserRouter basename={basename}>
              <BlueBixThemeProvider>
                <ErrorBoundary>

                  <Routes history={history} />
                </ErrorBoundary>
              </BlueBixThemeProvider>
            </BrowserRouter>
          </React.Suspense>
        </PersistGate>
      </Provider>
    </IntlProvider>
  );
}
