import React from 'react';
import { Form, Input, Button, message } from 'antd';
import axios from 'axios';
import { CONTACTS_CREATE } from '../../../../ApiUrl';

export default function QuickContactCreate({ companyId, token, onContactCreated }) {
    const [form] = Form.useForm();

    const onFinish = async (values) => {
        try {
            const contactData = {
                ...values,
                company_id: companyId,
                access: "public",
                contact_status: "active",
                category: "client",
                country: "United States",
                display_name: `${values.first_name} ${values.last_name}`,
                fax: "",
                skype_id: "",
                twitter_id: "",
                state: "",
                city: "",
                street_1: "",
                street_2: "",
                zip_code: "",
                description: "",
            };

            const response = await axios.post(CONTACTS_CREATE, contactData, {
                headers: { Authorization: token }
            });

            if (response.data && !response.data.error) {
                message.success('Contact created successfully');
                onContactCreated(response.data.data);
                form.resetFields();
            } else {
                throw new Error(response.data.message || 'Failed to create contact');
            }
        } catch (error) {
            console.error('Error creating contact:', error);
            message.error(error.response?.data?.message || 'Failed to create contact');
        }
    };

    return (
        <Form form={form} layout="vertical" onFinish={onFinish}>
            <Form.Item name="first_name" label="First Name" rules={[{ required: true, message: 'Please enter first name' }]}>
                <Input />
            </Form.Item>
            <Form.Item name="last_name" label="Last Name" rules={[{ required: true, message: 'Please enter last name' }]}>
                <Input />
            </Form.Item>
            <Form.Item name="email" label="Email" rules={[{ required: true, type: 'email', message: 'Please enter a valid email' }]}>
                <Input />
            </Form.Item>
            <Form.Item name="mobile" label="Mobile" rules={[{ required: true, message: 'Please enter mobile number' }]}>
                <Input />
            </Form.Item>
            <Form.Item>
                <Button type="primary" htmlType="submit">Add Contact</Button>
            </Form.Item>
        </Form>
    );
}