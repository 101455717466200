import {
	Avatar,
	Card,
	Checkbox,
	Divider,
	Tag,
	Typography,
	Button,
	Space,
	Row,
	Col,
	Radio,
	Timeline,
	Tabs,
	Image,
} from "antd";
import {
	EyeOutlined,
	SendOutlined,
	DeleteOutlined,
	EditOutlined,
	LinkedinOutlined,
	GlobalOutlined,
	DownloadOutlined,
} from "@ant-design/icons";
import BusinessCenterIcon from "@material-ui/icons/BusinessCenter";
import { NavLink } from "react-router-dom";
import ExpandableContent from "../../../../others/utils/ExpandableContent";
import { Email } from "@material-ui/icons";

const { Text, Link } = Typography;

export const CandidateCard = ({
	cndidate,
	candidate,
	candidateLabel,
	getNotes,
	record,
	currentListId,
	type,
	rolePath,
	selectedCandidateIds,
	employment,
	qualifications,
	emailHistory,
	EmailStatus,
	lists,
	LinkedInInvitationStatus,
	isValidEmails,
	handleCheckboxChange,
	handleLabelChange,
	handleAddToList,
	onClickSendEmail,
	openDeleteModal,
	IMAGE_CANDIDATE_URL,
	attachments,
}) => {
	return (
		<Card style={{ maxWidth: "100%" }}>
			<Row>
				<Col
					span={24}
					style={{
						display: "flex",
						justifyContent: "space-between",
						alignItems: "center",
					}}
				>
					<Col
						style={{
							display: "flex",
							gap: "16px",
							justifyContent: "space-between",
							alignItems: "center",
						}}
					>
						<Checkbox
							onChange={() => handleCheckboxChange(candidate?._id)}
							checked={selectedCandidateIds?.includes(candidate._id)}
						>
							{currentListId
								? lists
									.find((list) => list._id === currentListId)
									?.candidateIds?.includes(candidate._id)
									? "Remove from List"
									: "Add to List"
								: "Select"}
						</Checkbox>
						<Avatar
							style={{
								backgroundColor: "#ed7206",
								marginRight: "8px",
								display: "flex",
								justifyContent: "center",
								alignItems: "center",
							}}
							size={{
								xs: 24,
								sm: 32,
								md: 40,
								lg: 54,
								xl: 60,
								xxl: 100,
							}}
							icon={
								candidate.profile_image ? (
									<Image
										width={120}
										preview={false}
										src={IMAGE_CANDIDATE_URL + candidate.profile_image}
									/>
								) : (
									<Typography.Title className="mb-0 text-white">
										{candidate.first_name && candidate.first_name !== ""
											? candidate.first_name.charAt(0).toUpperCase()
											: ""}
									</Typography.Title>
								)
							}
						></Avatar>
						<Col>
							<Space
								style={{ display: "flex" }}
								direction="horizontal"
								size="middle"
							>
								<NavLink
									to={{
										pathname: `/${type}/candidateProfileDetail`,
										state: {
											item: {
												...candidate,
												opening_id: record.opening_id,
											},
										},
									}}
									style={{
										margin: 0,
										fontSize: "16px",
										fontWeight: "bold",
									}}
								>
									{candidate.first_name} {candidate.last_name}
								</NavLink>
								<Button
									onClick={() => {
										getNotes(candidate);
									}}
									title="Notes"
								>
									N
								</Button>
							</Space>

							<Typography.Text className="mb-5" type="secondary">
								{employment[0]?.is_current_company
									? employment[0].designation +
									" at " +
									employment[0].organization
									: "-"}
							</Typography.Text>
							<Typography.Text
								type="secondary"
								style={{
									display: "block",
									marginBottom: "8px",
								}}
							>
								{candidate.current_location}
							</Typography.Text>

							<div>
								<Col span={24}>
									<Space size={8} wrap>
										{candidate?.tags?.map((tag) => (
											<Tag key={tag} color="blue">
												{tag}
											</Tag>
										))}
									</Space>
								</Col>

								<Row className="p-1" align="middle">
									<Col span={24}>
										<Space direction="vertical" size={4}>
											<Space size={12}>
												{candidate?.meta?.linkedin && (
													<Typography.Link
														href={candidate.meta.linkedin}
														target="_blank"
														rel="noreferrer"
													>
														<LinkedinOutlined
															style={{
																fontSize: "20px",
																color: "#0077B5",
															}}
														/>
													</Typography.Link>
												)}
												{candidate?.meta?.Indeed && (
													<Typography.Link
														href={candidate.meta.Indeed}
														target="_blank"
														rel="noreferrer"
													>
														<GlobalOutlined
															style={{
																fontSize: "20px",
																color: "#2164f4",
															}}
														/>
													</Typography.Link>
												)}
											</Space>

										</Space>
										<Button
											onClick={() => {
												const link = document.createElement('a');
												link.href = `${process.env.REACT_APP_BASE_URL}/upload/candidate/${attachments}`;
												link.setAttribute('download', 'resume.pdf');
												document.body.appendChild(link);
												link.click();
												document.body.removeChild(link);
											}}
											icon={<DownloadOutlined />}
											type="primary"
											className="ml-5"
											size="small"
											title="Download Resume"
											disabled={!attachments}
										>
										</Button>
									</Col>

									<Col span={24}>
										<EmailStatus emailHistory={emailHistory} />
									</Col>
									<Col span={24}>
										<LinkedInInvitationStatus candidate={candidate} />
									</Col>
									{/* <Button
										onClick={handleAddToList}
										disabled={
											!currentListId || selectedCandidateIds.length === 0
										}
									>
										Add to Current List
									</Button> */}
								</Row>
							</div>
							<Col
								style={{
									display: "flex",
									justifyContent: "flex-start",
									alignItems: "center",
									gap: "8px",
								}}
							>
								{isValidEmails(candidate.email) ? (
									<div style={{ marginTop: "2px" }}>
										{candidate.email.split(",").map((email, index) => (
											<div
												key={index}
												style={{
													display: "flex",
													gap: "2px",
												}}
											>
												<Email />
												<p>{email.trim()}</p>
											</div>
										))}
									</div>
								) : null}{" "}
								{isValidEmails(candidate.email) ? (
									<Button
										title="Send Email"
										onClick={() => onClickSendEmail(cndidate)}
										icon={<SendOutlined />}
									/>
								) : null}
							</Col>
							<Space direction="vertical" size="middle">
								<Space direction="horizontal">
									<Radio.Group
										value={candidateLabel}
										onChange={(e) =>
											handleLabelChange(candidate._id, e.target.value)
										}
										size="small"
									>
										<Radio.Button value="top">Best</Radio.Button>
										<Radio.Button value="good">Good</Radio.Button>
										<Radio.Button value="qualified">Qualified</Radio.Button>
									</Radio.Group>
								</Space>
							</Space>
						</Col>
					</Col>
					{/* actions */}
					<Col
						style={{
							display: "flex",
							flexDirection: "column",
							gap: "4px",
						}}
					>
						<Space size="middle">
							<NavLink
								to={{
									pathname: `/${rolePath}/edit-candidate`,
									state: {
										item: {
											...candidate,
											opening_id: record.opening_id,
										},
									},
								}}
							>
								<Button type="primary" icon={<EditOutlined />} />
							</NavLink>
							<NavLink
								to={{
									pathname: `/${type}/candidateProfileDetail`,
									state: {
										item: {
											...candidate,
											opening_id: record.opening_id,
										},
									},
								}}
							>
								<Button type="primary" icon={<EyeOutlined />} />
							</NavLink>
							<Button
								type="danger"
								icon={<DeleteOutlined />}
								onClick={() => openDeleteModal(candidate._id)}
							></Button>
						</Space>
					</Col>
				</Col>
			</Row>
			<Divider></Divider>
			<Row>
				<Col span={2}>
					<Typography.Text>Experience</Typography.Text>
				</Col>

				<Col
					span="4"
					className=""
					style={{ display: "flex", alignItems: "center" }}
				>
					<BusinessCenterIcon style={{ color: "#000", marginRight: "3px" }} />
					<Typography.Text>
						{(candidate.total_work_exp_year ?? 0) +
							" Year(s) " +
							(candidate.total_work_exp_month ?? 0) +
							" Month(s)"}
					</Typography.Text>
				</Col>
				<Divider type="vertical" style={{ height: "auto" }} />
			</Row>

			<Row justify="start">
				<Col span={2}>
					<Typography.Text>Skills</Typography.Text>
				</Col>
				<Col span={8} className="">
					<ExpandableContent
						content={
							<div
								style={{
									display: "flex",
									flexWrap: "wrap",
									gap: "4px",
								}}
							>
								{candidate?.key_skills?.map((skill, index) => (
									<Typography.Text
										key={skill + index}
										style={{
											whiteSpace: "nowrap",
											marginRight: "8px",
										}}
									>
										{skill}
									</Typography.Text>
								))}
							</div>
						}
						maxHeight={48}
						showMoreText="Show more skills"
						showLessText="Show fewer skills"
					/>
				</Col>
				<Col span={14}>
					<Tabs
						defaultActiveKey="1"
						tabPosition="left"
						items={[
							{
								label: "Qualification",
								key: "1",
								children: (
									<Col span={12} className="">
										<ExpandableContent
											content={
												<Timeline className="p-1">
													{qualifications.map((qual, index) => (
														<Timeline.Item key={`qualification-${index}`}>
															<strong>{qual.course}</strong>
															{qual.university && (
																<span>
																	{" "}
																	from <strong>{qual.university}</strong>
																</span>
															)}
															<br />
															{qual.passing_year && <span>({qual.passing_year})</span>}
														</Timeline.Item>
													))}
												</Timeline>
											}
											maxHeight={58}
											showMoreText="Show more"
											showLessText="Show fewer"
										/>
									</Col>
								),
							},
							{
								label: "Employment",
								key: "2",
								children: (
									<Col span={12} className="">
										<ExpandableContent
											content={
												<Timeline className="p-1">
													{employment.map((job, index) => (
														<Timeline.Item key={index}>
															<strong>{job.designation}</strong> at{" "}
															<strong>{job.organization}</strong>
															<br />
															{job.work_since_from_year} -{" "}
															{job.is_current_company
																? "Present"
																: job.work_since_to_year || "N/A"}
															<br />
															{job.location}
														</Timeline.Item>
													))}
												</Timeline>
											}
											maxHeight={58}
											showMoreText="Show more"
											showLessText="Show fewer"
										/>
									</Col>
								),
							},
						]}
					/>
				</Col>
			</Row>
			<Row>
				<Col span={2}>
					<Typography.Text>Summary</Typography.Text>
				</Col>
				<Col span={16}>
					<ExpandableContent
						content={
							candidate.profile_summary ? (
								<Typography.Paragraph>
									{candidate.profile_summary}
								</Typography.Paragraph>
							) : null
						}
						maxHeight={22}
						showMoreText="Read more"
						showLessText="Read less"
					/>
				</Col>
			</Row>
		</Card>
	);
};
