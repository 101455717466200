import React, { useState } from 'react';
import axios from 'axios';
import { Col, Row, Card, Button, Input } from "antd";
import './style.css'
import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import { AUTO_SOURCE_BOOLSTRING, AUTO_SOURCE_SCRAPING } from "../../../ApiUrl";
import { logoutHandler } from '../../../others/logout';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
const NewAutoSource = () => {
    const { user, role } = useSelector(({ users }) => users);
    const [jobDescription, setJobDescription] = useState('');
    const [apiResult, setApiResult] = useState('');
    const [error, setError] = useState('');
    const [isRegerateVisible, setIsRegenarateVisible] = useState(false);
    const [isPullDataVisible, setIsPullDataVisible] = useState(false);
    const [instruction, setInstruction] = useState('');
    const [isButtonDisabled, setIsButtonDisabled] = useState(false);
    const history = useHistory();

    const handleInputChange = (event) => {
        setJobDescription(event.target.value);
    };
    const handleInstructionChange = (event) => {
        setInstruction(event.target.value);
    };
    const handleOutputChange = (event) => {
        setApiResult(event.target.value);
    };
    const generateSample = (event) => {
        setJobDescription(`Title: Geotechnical Principal Engineer

Must have: P.E OR Professional Engineer AND slope, earth, Soil, finite element
Requirements: modeling, stability, retention, shoring, settlement, foundations
Location: Detroit, MI

JD details: Bachelors of Science degree in Civil Engineering (emphasis in Geotechnical Engineering preferred) from a 4-year accredited college or university. Master of Science degree in Geotechnical Engineering preferred. Professional licensure in the State of Michigan or another state required. 10+ years of demonstrated experience in Civil Engineering or Geotechnical Engineering analysis and design. Excellent skills in geotechnical applications and soil-structure interaction. Experience with software for geotechnical analysis and design of slope stability, earth retention / shoring, settlement, vertical and lateral loading of shallow and deep foundations, and related items is necessary. Experience with soft soil conditions and finite element modeling is highly desirable. Ability to independently characterize subsoil conditions, starting from development of an exploration program through laboratory testing, evaluation of data, and design parameter recommendations. Leadership experience in mentoring and leading junior staff. Ability to organize technical material into comprehensive reports as well as perform QA/QC reviews on work product is required. Ability to perform project planning, task delegation, and management to ensure project is completed according to work plans. May be required to perform job duties in the field and office, during non-standard work hours, as necessary. Must be able to lift and carry equipment weighing up to 50 lbs. Must hold a valid driver’s license and be willing to travel to and conduct field work at job sites/offices in Michigan.  

`);
    };
    const handleSubmit = async () => {
        try {
            setIsButtonDisabled(true)
            setApiResult("Gpt is typing..")
            // Send POST request to your API endpoint
            const response = await axios.post(AUTO_SOURCE_BOOLSTRING, { jobpost: jobDescription, additional_instructions: instruction, user_id: "user-" + user._id });
            // Update API result state
            setApiResult(response.data.data);
            // Clear textarea after successful submission
            setIsRegenarateVisible(true);
            setIsPullDataVisible(true)
            setIsButtonDisabled(false)
            // You can add any additional logic here after successful submission
        } catch (error) {
            console.error('Error submitting job description:', error);
            setError('Error submitting job description. Please try again.');
            if (error?.response?.status == 401) {
                logoutHandler(history)
            }
        }
    };

    function downloadCSV(filename, data) {
        const csvContent = 'data:text/csv;charset=utf-8,' + encodeURIComponent(convertToCSV(data));
        const link = document.createElement('a');
        link.setAttribute('href', csvContent);
        link.setAttribute('download', filename);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link); // Clean up the link element
    }

    function convertToCSV(data) {
        // Custom header with the desired column order
        const customHeader = [
            'SN',
            'FullName',
            'FirstName',
            'LastName',
            'JobTitle',
            'Company',
            'URL',
            'Snippet',
            'ImagePath',
            'email',
            'phone_numbers'
        ];

        const rows = [];
        rows.push(customHeader.join(',')); // Add the header row

        // Iterate through the data and format each row
        for (const item of data) {
            const row = [
                item['SN'],
                item['FullName'],
                item['FirstName'],
                item['LastName'],
                item['JobTitle'],
                item['Company'],
                item['URL'],
                item['Snippet'],
                item['ImagePath'],
                item['email'],
                item['phone_numbers']
            ].map(value => `"${value}"`).join(',');

            rows.push(row);
        }

        return rows.join('\n');
    }
    const handlePullData = async () => {
        try {
            setIsButtonDisabled(true)
            setApiResult("Scraping..")
            // Make POST request to your API
            const response = await axios.post(AUTO_SOURCE_SCRAPING, { boolstring: apiResult, user_id: "" + user._id });

            //downloadCSV(response.data.data.csvFilename, response.data.data.csvData);

            setApiResult("Scraping added to queued successfully...")
            setIsButtonDisabled(false)
        } catch (error) {
            console.error('Error downloading file:', error);
            // Handle error as needed
            if (error?.response?.status == 401) {
                logoutHandler(history)
            }
        }
    };
    const createNewClientPath = role === "admin" ? 'admin' : role === "recruitmentmanager" ? 'recruitment-manager' : role === "bdmmanager" ? 'bdm-manager' : role;
    return (
        <div>
            <Card bordered={false} className="px-0 py-0 mb-12">
                <h2 className="text-center" title="Keyword">
                    Auto Sourcing
                </h2>
                <NavLink className={"float-right"} to={`/${createNewClientPath}/autosource`}>
                    <Button type="Secondary">Back</Button>
                </NavLink>

                <Row gutter={24} className="mb-0 align-items-center">
                    <Col span={12}>
                        <div>
                            <label htmlFor="jobDescription">Job Description:</label>
                            <br />
                            <textarea
                                id="jobDescription"
                                name="jobDescription"
                                rows="20"
                                cols="50"
                                className="form-control"
                                value={jobDescription}
                                onChange={handleInputChange}
                            />
                            <br />
                            <Row gutter={24} className="mt-3">
                                <Col span={12}>
                                    <Button
                                        className="ml-5"
                                        onClick={generateSample}
                                        type="primary"
                                        size="large"
                                        style={{ background: "#0dcaf0" }}
                                    >
                                        Generate Sample data
                                    </Button>
                                </Col>
                                <Col span={12}>
                                    <Button
                                        className="ml-5"
                                        onClick={handleSubmit}
                                        type="primary"
                                        size="large"
                                        disabled={isButtonDisabled}
                                    >
                                        Generate Boolean String
                                    </Button>
                                </Col>
                            </Row>

                        </div>
                    </Col>
                    <Col span={12}>
                        {error && <div style={{ color: 'red' }}>{error}</div>}

                        <div className="terminal">
                            <div className={`regenerate-section ${isRegerateVisible ? 'visible' : 'hidden'}`}>
                                <label htmlFor="regenerateInstructions">Regenerate Instructions:</label>
                                <div className="regenerate-controls">
                                    <input className="form-control" type="text" id="regenerateInstructions"
                                        name="regenerateInstructions"
                                        value={instruction}
                                        onChange={handleInstructionChange}
                                        placeholder="Enter regenetate instructions" />
                                    <button type="button" className="btn btn-danger" id="regenerateButton"
                                        onClick={handleSubmit}>
                                        Regenerate
                                    </button>
                                </div>
                            </div>
                            <div className="output-title">
                                Output
                            </div>
                            <textarea id="outputBox" value={apiResult}
                                onChange={handleOutputChange}
                                placeholder="OUTPUT" style={{ height: '400px' }}></textarea>
                            <br />
                            {isPullDataVisible && <button type="button" className={`btn btn-info`}
                                onClick={handlePullData} disabled={isButtonDisabled}
                            >
                                Pull Data
                            </button>
                            }
                        </div>
                    </Col>
                </Row>
            </Card>
        </div>
    );
};

export default NewAutoSource;