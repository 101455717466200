import { store } from "../redux";

export const requestHeader = () => {
    return {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
        Authorization: store.getState().users.token,
      },
    };
  };