import axios from 'axios';
import React, { useEffect, useState } from 'react';


const PdfViewer = ({ fileUrl }) => {
    const [iframeError, setIframeError] = useState(false);
    const [iframeLoading, setIframeLoading] = useState(true);


    useEffect(() => {
        if (fileUrl) {
            axios.get(fileUrl)
                .then(response => {
                    if (response.status !== 200) {
                        throw new Error('Network response was not ok');
                    }
                    setIframeError(false);
                })
                .catch(error => {
                    setIframeError(true);
                })
                .finally(() => {
                    setIframeLoading(false);
                });
        }
    }, [fileUrl]);
    return (
        <>
            {iframeLoading ? (
                <div style={{ textAlign: 'center', marginTop: '20px' }}>
                    <h3>Loading...</h3>
                </div>
            ) : !iframeError ? (
                <iframe
                    src={fileUrl}
                    style={{ height: "100vh", width: "100%" }}
                    frameBorder="0"
                ></iframe>
            ) : (
                <div style={{ textAlign: 'center', marginTop: '20px' }}>
                    <h3>Error: Document not found or unable to load</h3>
                </div>
            )}
        </>
    );
};

export default PdfViewer;
