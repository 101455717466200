exports.industries = [
    {
      value: "AFI",
      name: "Accounting/Finance/Insurance",
    },
    {
      value: "ACL",
      name: "Administrative/Clerical",
    },
    {
      value: "Ar85424",
      name: "Architectural &amp; Planning",
    },
    {
      value: "Au85425",
      name: "Automation",
    },
    {
      value: "BRM",
      name: "Banking/Real Estate/Mortgage Professionals",
    },
    {
      value: "Bi85435",
      name: "Biotechnology",
    },
    {
      value: "Bu85434",
      name: "Building Envelope/Materials",
    },
    {
      value: "BSM",
      name: "Business/Strategic Management",
    },
    {
      value: "EN",
      name: "Civil Engineering",
    },
    {
      value: "BST",
      name: "Construction",
    },
    {
      value: "CDN",
      name: "Creative/Design",
    },
    {
      value: "CSC",
      name: "Customer Support/Client Care",
    },
    {
      value: "EW",
      name: "Editorial/Writing",
    },
    {
      value: "ET",
      name: "Education/Training",
    },
    {
      value: "El85432",
      name: "Electrical &amp; Electronics Manufacturing",
    },
    {
      value: "Fa85428",
      name: "Farming",
    },
    {
      value: "Fo85433",
      name: "Food Processing/ Food Beverages",
    },
    {
      value: "FH",
      name: "Food Production",
    },
    {
      value: "hr",
      name: "Human Resources",
    },
    {
      value: "In85420",
      name: "Industrial",
    },
    {
      value: "it",
      name: "Information Technology",
    },
    {
      value: "IMP",
      name: "Installation/Maintenance/Repair",
    },
    {
      value: "L",
      name: "Legal",
    },
    {
      value: "LT",
      name: "Logistics/Transportation",
    },
    {
      value: "Ma85429",
      name: "Machinery",
    },
    {
      value: "MPO",
      name: "Manufacturing",
    },
    {
      value: "MP",
      name: "Marketing/Product",
    },
    {
      value: "Me85426",
      name: "Mechanical/Industrial Engineering",
    },
    {
      value: "MH",
      name: "Medical Devices",
    },
    {
      value: "Mi85430",
      name: "Mining &amp; Metals",
    },
    {
      value: "Oi85423",
      name: "Oil &amp; Energy",
    },
    {
      value: "Ga85422",
      name: "Oil &amp; Gas",
    },
    {
      value: "O",
      name: "Others",
    },
    {
      value: "Ph85421",
      name: "Pharmaceuticals",
    },
    {
      value: "Pl85427",
      name: "Plastic",
    },
    {
      value: "PPM",
      name: "Project/Program Management",
    },
    {
      value: "QS",
      name: "Quality Assurance/Safety",
    },
    {
      value: "R",
      name: "Research",
    },
    {
      value: "SRB",
      name: "Sales/Retail/Business Development",
    },
    {
      value: "SP",
      name: "Security/Protective Services",
    },
    {
      value: "Ut85431",
      name: "Utility Infrastructure",
    },
    {
        value: "PSs",
        name: "Professional Services",
      },
  ];
