import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import {
    JOB_REPORT_DOWNLOAD_LIST,
    BDM_REPORT_JOBS,
    RECRUITER_REPORT_JOBS,
    FREELANCE_REPORT_JOBS,
    CANDIDATE_REPORT_JOBS,
    JOB_BDM_LIST,
    JOB_RECRUITER_LIST,
} from '../../ApiUrl';

const createReportThunk = (name, url) => createAsyncThunk(
    `report/${name}`,
    async (params, { getState }) => {
        const { users } = getState();
        const response = await axios.post(url, params, {
            headers: { Authorization: users.token },
        });
        console.log('response for fetch data', response.data.data);
        return response.data.data;
    }
);
export const fetchJobReport = createReportThunk('fetchJobReport', JOB_REPORT_DOWNLOAD_LIST);
export const fetchBDMReport = createReportThunk('fetchBDMReport', BDM_REPORT_JOBS);
export const fetchRecruiterReport = createReportThunk('fetchRecruiterReport', RECRUITER_REPORT_JOBS);
export const fetchFreelanceReport = createReportThunk('fetchFreelanceReport', FREELANCE_REPORT_JOBS);
export const fetchCandidateReport = createReportThunk('fetchCandidateReport', CANDIDATE_REPORT_JOBS);

export const fetchBDMList = createAsyncThunk(
    'report/fetchBDMList',
    async (_, { getState }) => {
        const { users } = getState();
        const response = await axios.get(JOB_BDM_LIST, {
            headers: { Authorization: users.token },
        });
        console.log('bdm list', response.data.data);
        return response.data.data;
    }
);

export const fetchRecruiterList = createAsyncThunk(
    'report/fetchRecruiterList',
    async (_, { getState }) => {
        const { users } = getState();
        const response = await axios.get(JOB_RECRUITER_LIST, {
            headers: { Authorization: users.token },
        });
        console.log('recruiter list', response.data.data);
        return response.data.data;
    }
);

const initialState = {
    filters: {
        dateRange: [],
        status: '',
        bdm: '',
        recruiter: '',
    },
    jobReport: { data: null, loading: false, error: null },
    bdmReport: { data: null, loading: false, error: null },
    recruiterReport: { data: null, loading: false, error: null },
    freelanceReport: { data: null, loading: false, error: null },
    candidateReport: { data: null, loading: false, error: null },
    bdmList: [],
    recruiterList: [],
};

const reportSlice = createSlice({
    name: 'report',
    initialState,
    reducers: {
        updateFilters: (state, action) => {
            state.filters = { ...state.filters, ...action.payload };
        },
    },
    extraReducers: (builder) => {
        const addReportCases = (builder, thunk, reportKey) => {
            builder
                .addCase(thunk.pending, (state) => {
                    if (state[reportKey]) {
                        state[reportKey].loading = true;
                        state[reportKey].error = null;
                    }
                })
                .addCase(thunk.fulfilled, (state, action) => {
                    if (state[reportKey]) {
                        state[reportKey].loading = false;
                        state[reportKey].data = action.payload;
                        console.log('state[reportKey].data', state[reportKey].data);
                    }
                })
                .addCase(thunk.rejected, (state, action) => {
                    if (state[reportKey]) {
                        state[reportKey].loading = false;
                        state[reportKey].error = action.error.message;
                    }
                });
        };

        addReportCases(builder, fetchJobReport, 'jobReport');
        addReportCases(builder, fetchBDMReport, 'bdmReport');
        addReportCases(builder, fetchRecruiterReport, 'recruiterReport');
        addReportCases(builder, fetchFreelanceReport, 'freelanceReport');
        addReportCases(builder, fetchCandidateReport, 'candidateReport');

        builder
            .addCase(fetchBDMList.fulfilled, (state, action) => {
                state.bdmList = action.payload;
            })
            .addCase(fetchRecruiterList.fulfilled, (state, action) => {
                state.recruiterList = action.payload;
            });
    },
});

export const { updateFilters } = reportSlice.actions;
export default reportSlice.reducer;
// import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
// import axios from 'axios';
// import { debounce } from 'lodash';

// const debouncedApiCall = debounce(async (apiUrl, params) => {
//     const response = await axios.post(apiUrl, params, {
//         headers: { Authorization: params.token },
//     });
//     return response.data.data;
// }, 300);

// export const fetchReportData = createAsyncThunk(
//     'report/fetchReportData',
//     async ({ apiUrl, params }, { rejectWithValue }) => {
//         try {
//             return await debouncedApiCall(apiUrl, params);
//         } catch (error) {
//             return rejectWithValue(error.response.data);
//         }
//     }
// );

// const initialState = {
//     data: null,
//     loading: false,
//     error: null,
//     params: {},
// };

// const reportSlice = createSlice({
//     name: 'report',
//     initialState,
//     reducers: {
//         updateParams: (state, action) => {
//             if (JSON.stringify(state.params) !== JSON.stringify(action.payload)) {
//                 state.params = action.payload;
//             }
//         },
//     },
//     extraReducers: (builder) => {
//         builder
//             .addCase(fetchReportData.pending, (state) => {
//                 state.loading = true;
//                 state.error = null;
//             })
//             .addCase(fetchReportData.fulfilled, (state, action) => {
//                 state.loading = false;
//                 state.data = action.payload;
//             })
//             .addCase(fetchReportData.rejected, (state, action) => {
//                 state.loading = false;
//                 state.error = action.payload;
//             });
//     },
// });

// export const { updateParams } = reportSlice.actions;
// export default reportSlice.reducer;
// import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
// import axios from 'axios';

// export const fetchReportData = createAsyncThunk(
//     'report/fetchReportData',
//     async ({ apiUrl, params }, { rejectWithValue }) => {
//         try {
//             const response = await axios.post(apiUrl, params, {
//                 headers: { Authorization: params.token },
//             });
//             return response.data.data;
//         } catch (error) {
//             return rejectWithValue(error.response.data);
//         }
//     }
// );

// const initialState = {
//     data: null,
//     loading: false,
//     error: null,
//     params: {},
// };

// const reportSlice = createSlice({
//     name: 'report',
//     initialState,
//     reducers: {
//         updateParams: (state, action) => {
//             state.params = { ...state.params, ...action.payload };
//         },
//     },
//     extraReducers: (builder) => {
//         builder
//             .addCase(fetchReportData.pending, (state) => {
//                 state.loading = true;
//                 state.error = null;
//             })
//             .addCase(fetchReportData.fulfilled, (state, action) => {
//                 state.loading = false;
//                 state.data = action.payload;
//             })
//             .addCase(fetchReportData.rejected, (state, action) => {
//                 state.loading = false;
//                 state.error = action.payload;
//             });
//     },
// });

// export const { updateParams } = reportSlice.actions;
// export default reportSlice.reducer;