import React, { useState, useEffect } from "react";
import Chart from "react-apexcharts";
import { Card, Row, Col, List, Spin } from "antd";
import { Table } from "ant-table-extensions";
import { FREELANCE_REPORT_JOBS } from "../../../../../../ApiUrl";
import { OpeningStatusList } from "../../../../../pages/constant/constant";
import useReportData from "../../../../../../hooks/useReportData";

function FreelancerJobReport(props) {
  const [currentPage, setCurrentPage] = useState(1);
  const [chartStatus, setChartStatus] = useState("");

  const initialParams = {
    current_page: currentPage,
    per_page: "10",
    sort_order: "desc",
    filter_value: "",
    order: "created_at",
    dateRange: props.arrayDateRange,
    categories: [],
    status: props.statusLabal,
    company_id: "",
    bdm_id: props.bdm_id,
    recruiter_id: props.recruiter_id,
    freelancer_id: "",
  };

  const { data: openings, loading, error, updateParams } = useReportData(FREELANCE_REPORT_JOBS, initialParams);

  useEffect(() => {
    updateParams({
      current_page: currentPage,
      status: chartStatus ? OpeningStatusList.find(status => status.label === chartStatus)?.value : props.statusLabel,
    });
  }, [currentPage, chartStatus, props.statusLabel]);
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
  const columns = [
    {
      title: "Opening Title",
      dataIndex: "opening_title",
      key: "opening_title",
    },
    {
      title: "Opening id",
      dataIndex: "opening_id",
      key: "opening_id",
    },
    {
      title: "Working Freelancer",
      dataIndex: "freelancerDetails",
      key: "freelancerDetails",
      render: (text, record) => {
        return record?.freelancerDetails?.map((freelancer) => freelancer?.display_name).join(", ");
      }
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (Code) => {
        const status = OpeningStatusList.find((status) => {
          return status.value === Code;
        });
        return status ? status.label : Code;
      },
    },
  ];
  const newSeries = [];
  const newLabals = [];

  if (props && props.freelanceJobCount) {
    if (props.freelanceJobCount.Active) {
      newSeries.push(props.freelanceJobCount.Active);
      newLabals.push("Active");
    }
    if (props.freelanceJobCount.oh) {
      newSeries.push(props.freelanceJobCount.oh);
      newLabals.push("On Hold");
    }
    if (props.freelanceJobCount.I) {
      newSeries.push(props.freelanceJobCount.I);
      newLabals.push("Interview");
    }
    if (props.freelanceJobCount.rs) {
      newSeries.push(props.freelanceJobCount.rs);
      newLabals.push("Resume Screening");
    }
    if (props.freelanceJobCount.offer) {
      newSeries.push(props.freelanceJobCount.offer);
      newLabals.push("Offer");
    }
    if (props.freelanceJobCount.ri) {
      newSeries.push(props.freelanceJobCount.ri);
      newLabals.push("Closed");
    }
  }



  const data = {
    series: newSeries,
    options: {
      chart: {
        type: "donut",
        events: {
          dataPointSelection: (event, chartContext, config) => {
            const submissionStatus = OpeningStatusList.find((status) => {
              return (
                status.label === config.w.config.labels[config.dataPointIndex]
              );
            });
            setChartStatus(
              submissionStatus
                ? submissionStatus.label
                : config.w.config.labels[config.dataPointIndex]
            );
          },
        },
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200,
            },
            legend: {
              position: "bottom",
            },
          },
        },
      ],
      labels: newLabals,
    },
  };

  const DoughnutChart = () => (
    <Spin spinning={loading}>
      <Card title={"Assign Jobs"}>
        <Row gutter={24}>
          <Col span={12}>
            <div id="chart">
              <Chart

                options={data.options}
                series={data.series}
                type="donut"
                width="380"
              />
            </div>
          </Col>
          <Col span={12}>
            <List
              dataSource={newLabals}
              itemLayout={"horizontal"}
              bordered={false}
              renderItem={(item, index) => (
                <List.Item
                  style={{ "border-bottom": 0, padding: "5px 0" }}
                  key={index.toString()}
                >
                  <Col span={18} style={{ textAlign: "right" }}>
                    {item} :
                  </Col>
                  <Col span={6}>{newSeries[index]}</Col>
                </List.Item>
              )}
            />
          </Col>
        </Row>
      </Card>
      <br />
    </Spin>
  );

  const fields = {
    opening_id: {
      header: "Opening id",
      formatter: (_fieldValue, record) => {
        return record?.opening_id;
      },
    },
    opening_title: {
      header: "Opening Title",
      formatter: (_fieldValue, record) => {
        return record?.opening_title;
      },
    },
    status: {
      header: "Status",
      formatter: (_fieldValue, record) => {
        const status = OpeningStatusList.find((status) => {
          return status.value === record.status;
        });

        return status ? status.label : record.status;
      },
    },
  };

  const openingData = () => (
    <>
      <Card title="JOB Data" bordered={false} className="px-0 py-0">
        <Table
          pagination={{
            current: currentPage,
            total: openings?.totalRecords || 0,
            pageSize: 10,
            onChange: handlePageChange,
          }}
          dataSource={openings?.job_opening_listing || []}
          columns={columns}
          exportableProps={{ fields, fileName: "JOB Data" }}
        />
      </Card>
    </>
  );
  if (error) return <div>Error: {error.message}</div>;
  if (loading) return <Spin spinning={loading} />;
  const jobsOpnings = openings.job_opening_listing;
  const totalRecords = openings.totalRecords;

  return (
    <>
      {DoughnutChart()} {openingData()}
    </>
  );
}

export default FreelancerJobReport;
