
import React, { useState, useEffect, useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
	Button,
	Dropdown,
	Menu,
	Modal,
	Input,
	message,
	Select,
	Space,
	Tooltip,
} from "antd";
import { DownOutlined, PlusOutlined } from "@ant-design/icons";
import {
	createList,
	deleteList,
	updateList,
	setCurrentListId,
	fetchLists,
	setFilteredList,
	setSelectedCandidateIds,
	addCandidatesToList,
	removeCandidatesFromList,
	fetchCandidates,
	setTogglingCandidates,
	submitCandidates,
} from "../../../../redux/reducers/candidatesSlice";
import { Delete, Edit } from "@material-ui/icons";

const { Option } = Select;

export default function CandidateListManagement({
	jobId,
	isAutoSourced = true,
}) {
	const dispatch = useDispatch();
	const {
		lists,
		selectedCandidateIds,
		currentListId,
		isAddingToList,
		isRemovingFromList,
		isTogglingCandidates,
		submitting,
		isFetchingCandidates,
	} = useSelector((state) => state.candidates);
	const { user } = useSelector((state) => state.users);

	const [isModalVisible, setIsModalVisible] = useState(false);
	const [listName, setListName] = useState("");
	const [editingListId, setEditingListId] = useState(null);
	const [isAddToListModalVisible, setIsAddToListModalVisible] = useState(false);
	const [isDeleteFromListModalVisible, setIsDeleteFromListModalVisible] = useState(false);

	useEffect(() => {
		dispatch(fetchLists({ createdBy: user._id, jobId }));
		return () => {
			dispatch(setCurrentListId(null));
		};
	}, [dispatch, user._id, jobId]);
	useEffect(() => {
		if (lists.length > 0 && currentListId) {
			const currentList = lists.find(list => list._id === currentListId);
			if (!currentList || currentList.candidateCount === 0) {
				dispatch(setCurrentListId(null));
			}
		}
	}, [lists, currentListId, dispatch]);
	const showModal = (listId = null) => {
		setEditingListId(listId);
		setListName(
			listId ? lists.find((list) => list._id === listId)?.name || "" : ""
		);
		setIsModalVisible(true);
	};

	const handleCancel = () => {
		setIsModalVisible(false);
		setListName("");
		setEditingListId(null);
	};

	const handleCreateOrUpdateList = () => {
		if (listName.trim()) {
			if (editingListId) {
				dispatch(updateList({ listId: editingListId, newName: listName }))
					.then(() => {
						message.success("List updated successfully");
						handleCancel();
					})
					.catch(() => {
						message.error("Failed to update list");
					});
			} else {
				dispatch(createList({ listName, user, jobId }))
					.then(() => {
						message.success("New list created successfully");
						handleCancel();
					})
					.catch(() => {
						message.error("Failed to create new list");
					});
			}
		}
	};



	const handleAddToList = (listId) => {
		if (selectedCandidateIds.length > 0) {
			dispatch(
				addCandidatesToList({ listId, candidateIds: selectedCandidateIds })
			)
				.then(() => {
					message.success(
						`Added ${selectedCandidateIds.length} candidate(s) to the list.`
					);

					dispatch(fetchLists({ createdBy: user._id, jobId }));

					if (currentListId === listId) {
						dispatch(fetchCandidates({ jobId, shortlistId: listId }));
					}

					dispatch(setSelectedCandidateIds([]));
				})
				.catch((error) => {
					message.error("Failed to add candidates to the list");
				});
		} else {
			message.warning("Please select candidates to add to the list");
		}
		setIsAddToListModalVisible(false);
	};
	const handleRemoveFromList = (listId) => {
		if (listId) {
			dispatch(
				removeCandidatesFromList({
					listId: listId,
					candidateIds: selectedCandidateIds,
				})
			)
				.then(() => {
					message.success("Candidate removed from the list");
					dispatch(fetchCandidates({ jobId, shortlistId: listId }));
				})
				.catch(() => {
					message.error("Failed to remove candidate from the list");
				});
		} else {
			message.warning("No list selected");
		}
		setIsDeleteFromListModalVisible(false);
	};

	const handleDeleteList = (listId) => {
		dispatch(deleteList(listId))
			.then(() => {
				message.success("List deleted successfully");
				if (currentListId === listId) {
					dispatch(setCurrentListId(null));
				}
			})
			.catch(() => {
				message.error("Failed to delete list");
			});
	};

	const handleListChange = useCallback((listId) => {
		dispatch(setCurrentListId(listId));
		if (listId) {
			dispatch(fetchCandidates({ jobId, shortlistId: listId }));
		} else {
			dispatch(fetchCandidates({ jobId }));
		}
	}, [dispatch, jobId]);

	const showAddToListModal = () => {
		if (selectedCandidateIds.length > 0) {
			setIsAddToListModalVisible(true);
		} else {
			message.warning("Please select candidates to add to a list");
		}
	};

	const menu = (
		<Menu>
			<Menu.Item key="create" onClick={() => showModal()}>
				Create New List
			</Menu.Item>
			{lists?.map((list) => (
				<Menu.SubMenu
					key={list._id}
					title={`${list.name} (${list.candidateCount})`}
				>
					<Menu.Item
						key={`edit-${list._id}`}
						onClick={() => showModal(list._id)}
					>
						Edit
					</Menu.Item>
					<Menu.Item
						key={`delete-${list._id}`}
						onClick={() => handleDeleteList(list._id)}
					>
						Delete
					</Menu.Item>
				</Menu.SubMenu>
			))}
		</Menu>
	);
	const menuItems = [
		{
			key: 'create',
			label: (
				<span className="flex items-center">
					<PlusOutlined className="w-4 h-4 mr-2" />
					Create New List
				</span>
			),
			onClick: () => showModal(),
		},
		...lists.map((list) => ({
			key: list.id,
			label: `${list.name} (${list.candidateCount})`,
			children: [
				{
					key: `edit-${list.id}`,
					label: (
						<span className="flex items-center">
							<Edit className="w-4 h-4 mr-2" />
							Edit
						</span>
					),
					onClick: () => showModal(list.id),
				},
				{
					key: `delete-${list.id}`,
					label: (
						<span className="flex items-center">
							<Delete className="w-4 h-4 mr-2" />
							Delete
						</span>
					),
					onClick: () => handleDeleteList(list.id),
				},
			],
		})),
	]

	return (
		<>
			<Space direction="horizontal">
				<Select
					style={{ width: 200 }}
					placeholder="Select a list"
					onChange={handleListChange}
					value={currentListId}
					allowClear
					loading={isFetchingCandidates}
				>
					<Option value={null}>All Candidates</Option>
					{lists?.map((list) => (
						<Option key={list._id} value={list._id} disabled={list.candidateCount === 0}>
							{list.name} ({list.candidateCount})
						</Option>
					))}
				</Select>
				<Dropdown menu={{ items: menuItems }} arrow trigger={['click']}>
					<Button>
						Manage Lists <DownOutlined />
					</Button>
				</Dropdown>
				{currentListId ? (
					<Tooltip
						title={
							selectedCandidateIds.length === 0
								? "Select candidates to update the list"
								: ""
						}
					>
						<Button
							loading={isAddingToList}
							onClick={handleAddToList}
							hidden={currentListId}
						>
							Add to List
						</Button>
						<Button
							loading={isRemovingFromList}
							onClick={() => handleRemoveFromList(currentListId)}
							hidden={!currentListId || isDeleteFromListModalVisible}
						>
							Remove from List
						</Button>
					</Tooltip>
				) : (
					<Tooltip
						title={
							selectedCandidateIds.length === 0
								? "Select candidates to add to a list"
								: ""
						}
					>
						<Button
							onClick={showAddToListModal}
							disabled={selectedCandidateIds.length === 0 || isAddingToList}
							loading={isAddingToList}
							hidden={selectedCandidateIds.length === 0 || isAddingToList}
						>
							{isAddingToList
								? "Adding to List..."
								: `Add to List (${selectedCandidateIds.length})`}
						</Button>
					</Tooltip>
				)}

			</Space>

			<Modal
				title={editingListId ? "Edit List" : "Create New List"}
				open={isModalVisible}
				onOk={handleCreateOrUpdateList}
				onCancel={handleCancel}
			>
				<Input
					placeholder="Enter list name"
					value={listName}
					onChange={(e) => setListName(e.target.value)}
				/>
			</Modal>

			<Modal
				title="Add to List"
				open={isAddToListModalVisible}
				onCancel={() => setIsAddToListModalVisible(false)}
				footer={null}
			>
				<Select
					style={{ width: "100%" }}
					placeholder="Select a list to add candidates"
					onChange={handleAddToList}
				>
					{lists?.map((list) => (
						<Option key={list._id} value={list._id}>
							{list.name} ({list.candidateCount})
						</Option>
					))}
				</Select>
			</Modal>


		</>
	);
}
