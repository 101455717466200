import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {Modal, Table, Button, Spin, Typography, Card} from 'antd';
import {NavLink, useHistory} from 'react-router-dom';
import {useSelector} from "react-redux";
import {JOB_EMAIL_HISTORY} from "../../../ApiUrl";
import {CheckCircleOutlined, MailTwoTone, MessageOutlined} from "@ant-design/icons";
import {ReplyOutlined} from "@material-ui/icons";
const { Text, Title } = Typography;

const JobEmailHistoryModal = ({ openingId, visible, onClose }) => {
  const [emailHistory, setEmailHistory] = useState([]);
  const [loading, setLoading] = useState(false);
  const [total, setTotal] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const history = useHistory();
  const users = useSelector(({ users }) => users);
 const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState(null);
const candidateDetailsPath= users.role === "admin" ? 'admin' : users.role === "recruitmentmanager" ? 'recruitment-manager' : users.role === "bdmmanager" ? 'bdm-manager' : users.role;
  const handleChatClick = (record) => {
    setSelectedRecord(record);
    setIsModalVisible(true);
  };

  const handleModalClose = () => {
    setIsModalVisible(false);
    setSelectedRecord(null);
  };
  useEffect(() => {
    if (visible) {
      fetchEmailHistory();
    }
  }, [visible]);

  const fetchEmailHistory = async (page = 1) => {
    setLoading(true);
    try {
      const response = await axios.get(`${JOB_EMAIL_HISTORY}${openingId}`, {
        params: { page, limit: 10 }, 
        headers: { Authorization: users.token }
      });
      setEmailHistory(response.data.emails);
      setTotal(response.data.totalCount);
      setCurrentPage(response.data.currentPage);
    } catch (error) {
      console.error('Error fetching email history:', error);
    } finally {
      setLoading(false);
    }
  };

  const columns = [
       { title: 'Name', dataIndex: 'candidate_info', key: 'candidate_info',
       render: (data,record) => {
        return (
          <NavLink
              to={{
									pathname: `/${candidateDetailsPath}/candidateProfileDetail`,
									state: {
										item: {
                                                 candidate_id:record.candidate_id,
											opening_id: record.opening_id,
										},
									},
								}}
          >
               {data && data.length > 0 ? data[0].first_name : ''} {data && data.length && data[0].middle_name > 0 ? data[0].middle_name : ''} {data && data.length > 0 ? data[0].last_name : ''}
          </NavLink>
        );
      },
       }
      ,
       { title: 'Email', dataIndex: 'to_email', key: 'to_email' },
    {
      title: 'Open',
      dataIndex: 'is_open',
      key: 'is_open',
      render: (isOpen) =>
        isOpen ? (
          <MailTwoTone twoToneColor="#52c41a" />
        ) : (
          <MailTwoTone twoToneColor="#ff4d4f" />
        ),
    },
    {
      title: 'Replied',
      dataIndex: 'is_replied',
      key: 'is_replied',
      render: (isReplied) =>
        isReplied ? (
          <ReplyOutlined style={{ color: '#52c41a' }} />
        ) : (
          <ReplyOutlined style={{ color: '#ff4d4f' }} />
        ),
    },
    {
      title: 'Bounced',
      dataIndex: 'is_bounced',
      key: 'is_bounced',
      render: (isBounced) =>
        isBounced ? (
          <CheckCircleOutlined style={{ color: '#52c41a' }} />
        ) : (
          <CheckCircleOutlined style={{ color: '#ff4d4f' }} />
        ),
    },
    {
      title: 'Action',
      key: 'action',
      render: (text, record) => (
        <Button onClick={() => handleChatClick(record)}>
          <MessageOutlined />
        </Button>
      ),
    },
  ];

  return (
    <Modal
      title="Email History"
      open={visible}
      onCancel={onClose}
      footer={null}
      width={800}
    >
      {loading ? <Spin /> : (
        <Table
          dataSource={emailHistory}
          columns={columns}
          pagination={{
            total,
            pageSize: 10,
            current: currentPage, 
            onChange: (page) => {
              setCurrentPage(page); 
              fetchEmailHistory(page); 
            }
          }}
          rowKey="_id"
        />
      )}
      {selectedRecord && (
          <Modal
              title="Email Conversation"
              open={isModalVisible}
              onCancel={handleModalClose}
              footer={null}
          >
              <div>
                  {/* Message Box */}
                  <Card className="message-box" style={{marginBottom: '20px', backgroundColor: '#f0f2f5'}}>
                      <Text strong>Sent Message:</Text>
                      <br/>
                      <Text>From: {selectedRecord.from_email}</Text>
                      <br/>
                      <Text>To: {selectedRecord.to_email}</Text>
                      <br/>
                      <Text>Subject: {selectedRecord.subject}</Text>
                      <br/>
                      <div
                          dangerouslySetInnerHTML={{__html: selectedRecord.message}}
                          style={{
                              padding: '10px',
                              whiteSpace: 'pre-wrap',
                              borderRadius: '5px',
                              backgroundColor: '#fff'
                          }}
                      />
                      <Text type="secondary">{new Date(selectedRecord.created_at).toLocaleString()}</Text>
                  </Card>

                  {/* Reply Box */}
                  {selectedRecord.is_replied && (
                      <Card className="reply-box" style={{backgroundColor: '#e6fffb'}}>
                          <Text strong>Reply Message:</Text>
                          <br/>
                          <Text>From: {selectedRecord.to_email}</Text>
                      <br/>
                          <div
                              dangerouslySetInnerHTML={{__html: selectedRecord.reply_message}}
                              style={{
                                  padding: '10px',
                                  whiteSpace: 'pre-wrap',
                                  borderRadius: '5px',
                                  backgroundColor: '#fff'
                              }}
                          />
                          <br/>
                          <Text type="secondary">
                              {new Date(selectedRecord.meta.reply_receivedDateTime).toLocaleString()}
                          </Text>
                      </Card>
                  )}
              </div>
          </Modal>
      )}
    </Modal>

  );
};

export default JobEmailHistoryModal;
