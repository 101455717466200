import { Button, Modal, Input } from "antd";
import React, { useState } from "react";
import axios from "axios";
import { OUTLOOK_REPLY_MAIL } from "../../../ApiUrl";
import { useSelector } from "react-redux";

const EmailDetailPopup = ({ show, setShow, emailDetail, setEmailDetail, inbox }) => {
  const [isReplying, setIsReplying] = useState(false);
  const [replyContent, setReplyContent] = useState("");
  const [loading, setLoading] = useState(false);
  const [replyingEmail, setReplyingEmail] = useState(null);
  const users = useSelector(({ users }) => users);
  const storedEmail = localStorage.getItem("email");

  const handleClose = () => {
    setShow(false);
    setEmailDetail(undefined);
    setIsReplying(false);
    setReplyContent("");
  };

  const handleReplyClick = () => {

    const email = inbox
      .flatMap((emailArr) => emailArr)
      .find((email) => email.msgId === emailDetail.msgId);

    console.log("Found Email to Reply:", email);

    if (email) {
      setReplyingEmail(email);
      setReplyContent("");
      setIsReplying(true);
    } else {
      console.error("Email to reply not found.");
    }
  };

  const handleReplyContentChange = (e) => {
    setReplyContent(e.target.value);
  };

  const handleSendReply = async () => {
    if (!replyingEmail) return;
console.log(replyingEmail);
    const subject = Array.isArray(replyingEmail.headerData.subject)
      ? replyingEmail.headerData.subject.join(", ")
      : replyingEmail.headerData.subject;

    try {
      setLoading(true);
      const response = await axios.post(
        OUTLOOK_REPLY_MAIL,
        {
          messageId: replyingEmail.Id,
          to: replyingEmail.headerData.from,
          subject: `Re: ${subject}`,
          body: replyContent,
          from: replyingEmail.headerData.to,
          userid: users.user._id,
        },
        {
          headers: {
            Authorization: users.token },
        }
      );

      console.log("Reply sent successfully:", response.data);
      setIsReplying(false);
      setReplyContent("");
    } catch (error) {
      console.error(
        "Error sending reply:",
        error.response ? error.response.data : error.message
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <Modal
        visible={show}
        title="Email Detail"
        width={620}
        onCancel={handleClose}
        bodyStyle={{ height: 620 }}
        footer={
          <div style={{ textAlign: "right" }}>
            {!isReplying ? (
              <>
                <Button
                  onClick={handleReplyClick}
                  style={{ marginRight: 8 }}
                  disabled={loading}
                >
                  Reply
                </Button>
                <Button onClick={handleClose} style={{ marginRight: 8 }}>
                  Cancel
                </Button>
              </>
            ) : (
              <>
                <Input.TextArea
                  value={replyContent}
                  onChange={handleReplyContentChange}
                  rows={4}
                  placeholder="Enter your reply here..."
                />
                <Button
                  onClick={handleSendReply}
                  style={{ marginRight: 8, marginTop: 8 }}
                  loading={loading}
                >
                  Send
                </Button>
                <Button
                  onClick={() => setIsReplying(false)}
                  style={{ marginRight: 8, marginTop: 8 }}
                >
                  Cancel
                </Button>
              </>
            )}
          </div>
        }
      >
        <iframe
          srcDoc={emailDetail?.html || "N/A"}
          title="email detail"
          width={"100%"}
          height={"100%"}
        />
      </Modal>
    </div>
  );
};

export default EmailDetailPopup;
